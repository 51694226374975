import styled from 'styled-components'

const StyledCanvasWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .noise {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/img/noise.png');
    background-size: cover;
    z-index: 2;
  }
`


export {
  StyledCanvasWrapper
}