import './App.css';
import { Route, Redirect, Switch, BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import './index.css';
import {
  Home,
  BeliefPage,
  StoryPage,
  CookiePrivacy,
  CompetencyPage,
} from './containers';

import {
  CurtainLoad,
  OverlayManifesto
} from './components';

function App() {

  return (
  <>
    <BrowserRouter basename={"/"}>
      <Provider store={store}>
        <Switch>
          <Route
            path={"/"}
            exact
            component={(props) => <Home {...props} />}
          />
          <Route
            path={"/privacy-cookie-policy"}
            exact
            component={(props) => <CookiePrivacy {...props} />}
          />
          <Route
            path={"/belief/:id"}
            exact
            component={(props) => <BeliefPage {...props} />}
          />
          <Route
            path={"/competency-model"}
            exact
            component={(props) => <CompetencyPage {...props} />}
          />
          <Route
            path={"/story/:id"}
            exact
            component={(props) => <StoryPage {...props} />}
          />
          <Redirect to={"/"}/>
        </Switch>
        <CurtainLoad />
        <OverlayManifesto />
      </Provider>
    </BrowserRouter>
  </>
);
}

export default App;
