import React, { Suspense } from "react";
import GradientBg from '../GradientBg/GradientBg';
import { Canvas } from '@react-three/fiber'
import { StyledCanvasWrapper } from './StylesBackroung';

const Background = ({isFixed}) => {

  return(
    <>
      <StyledCanvasWrapper>
        <div className="noise"></div>
        <Canvas pixelRatio={[1,1]} resize={{ scroll: false, debounce: 100 }} >
          <Suspense fallback={null}>
            <GradientBg />
          </Suspense>
        </Canvas>
      </StyledCanvasWrapper>
      
    </>
  )
}

export default Background;
