import styled from "styled-components";

const ContentCompetencyStyles = styled.article`
  position: relative;
  width: 50%;
  margin: 0 auto;
  @media screen and (max-width: 1023px){
    width: 66.66%;
  }
  @media screen and (max-width: 767px){
    width: calc(100% - 24px);
  }
  h3 {
    color: #001A72;
    font-size: 26px;
    line-height: 32px;
    text-align: left;
    padding: 0;
    font-family: 'Everet Light';
    margin-bottom: 30px;
    padding-bottom: 30px;
    margin-top: 100px;
    border-bottom: 1px solid #D9DDEA;
    @media screen and (max-width: 1023px){
      margin-top: 80px;
    }
    @media screen and (max-width: 767px){
      font-size: 22px;
      line-height: 28px;
      margin-top: 60px;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }
    
  }
  p.content-text {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 100px;
    font-weight: 600;
    color: #001A72;
    @media screen and (max-width: 1023px){
      margin-bottom: 80px;
    }
    @media screen and (max-width: 767px){
      margin-bottom: 60px;
    }
  }
`

export {
  ContentCompetencyStyles
}