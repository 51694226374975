import styled from "styled-components";

const StyledHeaderStoryPage = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  @media screen and (max-width: 767px){
    height: auto;
  }   
  .header-bar {
    position: absolute;
    height: 74px;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 3;
    width: 100%;
    border-bottom: 1px solid rgba(255,255,255,0.15);
    .back-btn {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 24px;
      cursor: pointer;
      img {
        margin-right: 15px;
      }
      p {
        font-size: 12px;
        line-height: 18px;
        color: #fff;
      }
    }
  }
  .video-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    @media screen and (max-width: 767px){
      height: auto;
    }
    .poster {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-size: cover;
      background-position: 50%;
      opacity: 1;
      transition: opacity 0.35s cubic-bezier(0.47, 0, 0.745, 0.715);
      display: flex;
      align-items: center;
      text-align: center;
      z-index: 2;
      img {
        margin: 0 auto;
        cursor: pointer;
      }
      &.play--video {
        opacity: 0;
        
        img {
          cursor: none;
        }
      }
    }
    .video-frame {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: 1;
      display: flex;
      align-items: center;
      background-color: #000;
      @media screen and (max-width: 767px){
        position: relative;
      }
      iframe {
        position: relative;
        width: 100%;
      }
    }
  }
  
 
`;

export {
  StyledHeaderStoryPage
}