import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import {
  LinkBeliefWrap,
  LinkCardWrap,
  LinkCard
} from './StylesLinkBelief';
import { transitionLoader } from '../../redux/actions/generalActions';

const LinkBelief = () => {
  const beliefs = [
    "design-for-value",
    "will-to-achieve",
    "quest-to-evolve",
    "care-for-balance"
  ];
  const slug = useParams();
  const history = useHistory()
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  const isTriggerLoad = useSelector((state) => state.general.triggerLoader);;
  
  const handleChange = useCallback((url) => {
    
    dispatch(transitionLoader(true));

    if(!isTriggerLoad) setTimeout(() => history.push(url), 1000);
    
   }, [isTriggerLoad])


  return(
  <LinkBeliefWrap>
    <h6>
      {
        t('nextBeliefsCta')
      }
    </h6>
      <LinkCardWrap>
      {
        beliefs.filter((link) => link !== slug.id).map((item) => {
          let transArrayItemTitle = `beliefsPage.${item}.titleCard`
          let transArrayItemSubitle = `beliefsPage.${item}.subtitle`
          return <LinkCard key={item} onClick={() => handleChange(`/belief/${item}`)}>
                    <div className="noise"></div>
                    <div className="card--text-wrap">
                      <h4 dangerouslySetInnerHTML={{
                        __html: t(transArrayItemTitle)
                      }}></h4>
                      <div className="subtitle--mask">
                        <p>
                          {t(transArrayItemSubitle)}
                        </p>
                      </div>
                    </div>
                  </LinkCard>
        })
      }
      </LinkCardWrap>
  </LinkBeliefWrap>
  )
}

export default withTranslation()(LinkBelief);