import styled from "styled-components";

const ContentStoryStyles = styled.article`
  position: relative;
  width: 50%;
  margin: 0 auto;
  @media screen and (max-width: 1023px){
    width: 66.66%;
  }
  @media screen and (max-width: 767px){
    width: calc(100% - 24px);
  }
  h3 {
    color: #001A72;
    font-size: 45px;
    line-height: 54px;
    font-family: 'Everet Light';
    margin-bottom: 30px;
    margin-top: 100px;
    @media screen and (max-width: 1023px){
      margin-top: 80px;
    }
    @media screen and (max-width: 767px){
      font-size: 30px;
      line-height: 38px;
      margin-top: 60px;
      margin-bottom: 20px;
    }
  }
  p.author,
  p.author-role {
    color: #001A72;
    font-size: 18px;
    line-height: 30px;
    @media screen and (max-width: 767px){
      font-size: 16px;
    }
  }
  p.author-role {
    font-family: 'Everet Light';
    padding-bottom: 35px;
    margin-bottom: 35px;
    border-bottom: 1px solid #D9DDEA;
    @media screen and (max-width: 767px){
      padding-bottom: 20px;
      margin-bottom: 20px;
    }
  }
  p.content-text {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 100px;
    @media screen and (max-width: 1023px){
      margin-bottom: 80px;
    }
    @media screen and (max-width: 767px){
      margin-bottom: 60px;
    }
  }
`

export {
  ContentStoryStyles
}