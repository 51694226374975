import { useEffect, useState } from 'react';
import {
  StyledSwitchedLang
} from './StylesSwitchLang';
import { useTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { transitionLoader, toggleMenuMobile } from '../../redux/actions/generalActions';

const SwitchLang = ({isWhiteBg}) => {
  const { t, i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(null);
  const [openSelect, setOpenSelect] = useState(false);
  const dispatch = useDispatch()
  const isMobileMenuOpen = useSelector((state) => state.general.isMenuMobileOpen);

  useEffect(() => {
    if(t !== undefined)
      setCurrentLang(i18n.language)
  },[t])
 
  const changeLang = (e) => {
    const target = e.currentTarget;
    const data = target.getAttribute('data-lang');
    const label = target.querySelector('p')

    target.setAttribute('data-lang', currentLang);
    label.innerText = currentLang;
    setCurrentLang(data);
    setOpenSelect(!openSelect);

    if(isMobileMenuOpen)
      dispatch(toggleMenuMobile(!isMobileMenuOpen));

    setTimeout(() => {
      dispatch(transitionLoader(true))
    },isMobileMenuOpen ? 250 : 0)
    setTimeout(() => {
      i18n.changeLanguage(data);
    }, isMobileMenuOpen ? 1200 : 1000);
    
  }

  const toggleOpenLang = () => {
    setOpenSelect(!openSelect)
  } 

  const langs = [
    "en",
    "it",
    "es",
    "fr",
    "de",
    "nl"
  ]
  
  return (
    <>
      <StyledSwitchedLang isWhiteBg={isWhiteBg} className={`${openSelect ? 'open' : ''}`}>
        {
          currentLang !== null &&
          <>
            <div className="current-lang" onClick={toggleOpenLang}>
              <p>{currentLang}</p>
              <div className="arrow-wrap">
                <svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.999999 1L5.5 6L10 0.999999" stroke=""/>
                </svg>
              </div>
            </div>
            <div className="list-lang">
              { 
                langs.filter( lang => lang !== currentLang ).map((item) => {
                  return <div key={item} className="lang-item" onClick={(e) => changeLang(e)} data-lang={item}>
                          <p>{item}</p>
                        </div>
                })
              }
            </div>
          </>
        }
      </StyledSwitchedLang>
    </>
  )
}

export default SwitchLang;