import styled from "styled-components";

const NextStoryWrap = styled.article`
  position: relative;
  height: auto;
  background: linear-gradient(45deg, #009ee2 32%,#e5007e 75%);
  background-size: 150% 150%;
  background-position: 0% 100%;
  animation: gradientLoop 8s ease-in-out infinite;
  padding: 0 40px;
  .content {
    position: relative;
    width: 66%;
    margin: 0 auto;
    cursor: pointer;
    @media screen and (max-width: 1023px) {
      width: 100%;
    }
  }
  h4 {
    font-size: 14px;
    margin: 0 auto;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    text-transform: uppercase;
    padding-top: 70px;
    padding-bottom: 40px;
  }
  img {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }
  .details-row {
    position: relative;
    width: 100%;
    padding-bottom: 70px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media screen and (max-width: 1023px) {
      flex-direction: column;
    }
  }
  h3 {
    font-size: 26px;
    line-height: 32px;
    flex: 0 0 65%;
    color: #fff;
    font-weight: 400;
    font-family: 'Everet Light';
    margin: 0;
    @media screen and (max-width: 1023px) {
      font-size: 22px;
      line-height: 28px;
      flex: 0 0 100%;
      margin-bottom: 20px;
    }
  }
  .author-wrap {
    flex: 0 0 30%;
    @media screen and (max-width: 1023px) {
      flex: 0 0 100%;
    }
  }
  p.author,
  p.author-role {
    color: #fff;
    font-size: 14px;
    line-height: 17px;
  }
  p.author-role {
    font-family: 'Everet Light';
    padding-bottom: 76px;
  }
  @keyframes gradientLoop {
    0% {
      background-position: 0% 100%;
    }
    50% {
      background-position: 100% 0%;
    }
    100% {
      background-position: 0% 100%;
    }
  }
`;

export {
  NextStoryWrap
}