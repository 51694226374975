import {
  TOGGLE_MENU,
  TOGGLE_OVERLAY,
  TRIGGER_LOADER,
  TOGGLE_MANIFESTO,
  TOGGLE_MENU_MOBILE,
  GO_TO_SLIDE,
  UPDATE_ID_SLIDE
} from "../types";

export const toggleMenu = (value) => {
  return {
    type: TOGGLE_MENU,
    value
  };
};

export const toggleOverlay = (value) => {
  return {
    type: TOGGLE_OVERLAY,
    value
  };
};

export const transitionLoader = (value) => {
  return {
    type: TRIGGER_LOADER,
    value
  };
};

export const toggleManifesto = (value) => {
  return {
    type: TOGGLE_MANIFESTO,
    value
  };
}

export const toggleMenuMobile = (value) => {
  return {
    type: TOGGLE_MENU_MOBILE,
    value
  };
}

export const goToSlide = (value) => {
  return {
    type: GO_TO_SLIDE,
    value
  }
}

export const updateOnBack = (value) => {
  return {
    type: UPDATE_ID_SLIDE,
    value
  }
}
