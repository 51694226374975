import {
  NextStoryWrap
} from './StylesNextStoryLink'
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import {
  transitionLoader,
} from '../../redux/actions/generalActions';
import { useLocation } from "react-router";

const NextStoryLink = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const slug = useParams();
  const { t } = useTranslation();
  const parent = useRef(null);
  const nextId = t(`storiesPage.${slug.id}.next-story-id`);
  const isTriggerLoad = useSelector((state) => state.general.triggerLoader);;
  const location = useLocation()

  useEffect(() => {
    parent.current.parentNode.scrollTo(0,0);
  }, [location]);

  const handleChange = () => {
    
    dispatch(transitionLoader(true));
    
    if(!isTriggerLoad) setTimeout(() => {
      history.push(`/story/${nextId}`);
      
    }, 1000);
  }
  return(
    <NextStoryWrap ref={parent}>
      <div className="content" onClick={handleChange}>
        <h4>
          {t('next-story')}
        </h4>
        <img src={t(`storiesPage.${nextId}.slide-img`)} />
        <div className="details-row">
          <h3>
            {t(`storiesPage.${nextId}.excerpt-title`)}
          </h3>
          <div className="author-wrap">
            <p className="author">
              {t(`storiesPage.${nextId}.author`)}
            </p>
            <p className="author-role">
              {t(`storiesPage.${nextId}.author-role`)}
            </p>
          </div>
        </div>
        
        
      </div>
    </NextStoryWrap>
  )
}

export default withTranslation()(NextStoryLink);