import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  goToSlide,
  transitionLoader,
  updateOnBack
} from '../../redux/actions/generalActions';
import { useTranslation, withTranslation } from 'react-i18next';
import {StyledHeaderBeliefPage} from './StylesHeaderBeliefPage';

const HeaderBeliefPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const slug = useParams();
  const { t } = useTranslation()
  const [firstLoad, setFirstLoad] = useState(false)
  const isTriggerLoad = useSelector((state) => state.general.triggerLoader);
  
  const goBack = () => {
    
    dispatch(transitionLoader(true));
    dispatch(updateOnBack(true));
    
    if(!isTriggerLoad) setTimeout(() => {
      history.push('/');
      
      dispatch(goToSlide(3));
    }, 1000);
  }

  useEffect(() => {
    setTimeout(() => setFirstLoad(false), 0);
    setTimeout(() => setFirstLoad(true), 1000);
  }, [slug])

  return(
    <StyledHeaderBeliefPage isLoaded={firstLoad}>
      <div className="grain"></div>
      <div className="header-bar">
        <div className="back-btn" onClick={goBack}>
          <img src="/img/arrow-white-back.svg" />
          <p>BELIEFS</p>
        </div>
      </div>
      <div className="title-wrap" >
        <div className="mask-text">
          <h2>
            {
              t(`beliefsPage.${slug.id}.title`)}
          </h2>
        </div>
        <div className="mask-text">
          <p>
            {
              t(`beliefsPage.${slug.id}.subtitle`)
            }
          </p>
        </div>
      </div>
    </StyledHeaderBeliefPage>
  )
}

export default withTranslation()(HeaderBeliefPage);