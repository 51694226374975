import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';

import {
  ContentStoryStyles
} from './StylesContentStory';

const ContentStoryPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const slug = useParams();
  const { t } = useTranslation()
  const [firstLoad, setFirstLoad] = useState(false);

  useEffect(() => {
    setTimeout(() => setFirstLoad(false), 0);
    setTimeout(() => setFirstLoad(true), 2250);
  }, [slug]);

  return(
    <ContentStoryStyles>
      <h3 dangerouslySetInnerHTML={{
          __html: t(`storiesPage.${slug.id}.article-title`)
        }}>
      </h3>
      <p className="author" 
        dangerouslySetInnerHTML={{
          __html: t(`storiesPage.${slug.id}.author`)
        }}></p>
      <p className="author-role" 
        dangerouslySetInnerHTML={{
          __html: t(`storiesPage.${slug.id}.author-role`)
        }}></p>
      <p className="content-text"
        dangerouslySetInnerHTML={{
          __html: t(`storiesPage.${slug.id}.article-text`)
        }}></p>
    </ContentStoryStyles>
  );
}

export default withTranslation()(ContentStoryPage);