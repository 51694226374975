import {
  CompetencyPageStyles,
} from './CompetencyPageStyles';
import React, { useEffect, useRef, useState } from "react";
import {
  HeaderVideoCompetency,
  ContentCompetencyPage,
  CompetencyOverlayRow
} from '../../components'

const CompetencyPage = () => {
 
  const parent = useRef(null);
 

  return (
    <CompetencyPageStyles ref={parent}>
      <HeaderVideoCompetency />
      <ContentCompetencyPage />
      <CompetencyOverlayRow />
    </CompetencyPageStyles>
  )
}

export default CompetencyPage;
