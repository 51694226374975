import { useTranslation, withTranslation } from 'react-i18next';
import { CursorWrap } from './StylesCursor';

const InteractiveCursor = ({position, isShown, statusHover, isFixed}) => {
  const {t, i18n} = useTranslation();

  return (
    <CursorWrap 
      position={position} 
      isShown={isShown}
      isFixed={isFixed}
      style={{
        left: `${position.x }px`,
        top: `${position.y }px`
      }}
    >
      {
        (statusHover !== null &&
        statusHover === 'active') &&
        <p>{t('read-more')}</p>
      }
      {
        (statusHover !== null &&
        statusHover === 'prev') &&
        <img src="/img/stories-arrow-prev.svg" />
      }
      {
        (statusHover !== null &&
        statusHover === 'next') &&
        <img src="/img/stories-arrow-next.svg" />
      }
      {
        (statusHover !== null &&
        statusHover === 'view') &&
        <p>{t('read-more')}</p>
      }
    </CursorWrap>
  )
}

export default withTranslation()(InteractiveCursor);