import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, withTranslation } from 'react-i18next';
import { toggleManifesto } from "../../redux/actions/generalActions";
import Scrollbar from 'smooth-scrollbar';

import {
  StyledOverlayManifesto
} from './StylesOverlayManifesto';

const OverlayManifesto = () => {
  const dispatch = useDispatch();
  const overlay = useRef(null)
  const {t, i18n} = useTranslation();
  const isManifestoOpen = useSelector((state) => state.general.isManifestoOpen);

  const closeOverlay = () => {
    dispatch(toggleManifesto(false));
  }

  useEffect(()=>{
    if(overlay.current !== null)
    Scrollbar.init(overlay.current, {damping: 0.075})
  },[overlay])

  return (
    <StyledOverlayManifesto show={isManifestoOpen} ref={overlay}>
      <div className="close-manifesto" onClick={closeOverlay}>
        <img src="/img/close-video.svg" />
      </div>
      <div className="full-manifesto" dangerouslySetInnerHTML={{ __html: t('manifestoText')}}></div>
    </StyledOverlayManifesto>
  )
}

export default withTranslation()(OverlayManifesto);