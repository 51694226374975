import styled from "styled-components";

const BeliefSection = styled.section`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  
`;

export {
  BeliefSection
}