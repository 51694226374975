import styled from "styled-components";

const StyledSliderWrap = styled.section`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 2;
  opacity: ${props => props.hide ? 0 : 1 };
  transition: opacity 0.25s ease-in-out ${props => props.hide ? '0s' : '0.25s'};
`;
const StyledSliderMain = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  .swiper-container,
  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .swiper-slide {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    &.home--slide {
      background-image: url('./img/flare.png');
      background-position: 50% 0;
      background-size: cover;
    }
    .logo--wrap {
      position: absolute;
      width: 130px;
      top: 60px;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 100%;
        display: block;
      }
      @media screen and (max-width: 1023px){
        top: 30px;
      }
    }
    h1 {
      color: #fff;
      text-align: center;
      font-size: 115px;
      line-height: 120px;
      font-family: 'Everet Light';
      @media screen and (max-width: 1023px){
        font-size: 95px;
        line-height: 100px;
      }
      @media screen and (max-width: 768px){
        font-size: 64px;
        line-height: 70px;
      }
    }
    .cookie-page {
      position: absolute;
      bottom: 20px;
      right: 20px;
      text-align: right;
      color: #fff;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      a {
        color: inherit;
      }
      @media screen and (max-width: 1023px){
        display: none;
      }
    }
  }
`;

const StyledScrollIndicator = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  span {
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
  }
  .line--indicator {
    position: relative;
    margin: 0 auto;
    width: 1px;
    height: 60px;
    overflow: hidden;
    margin-top: 20px;
    background-color: rgba(255, 255, 255, 0.5);
    &::after {
      content: '';
      position: absolute;
      top: 0;
      height: 0;
      width: 1px;
      background-color: #fff;
      animation: scrollLoop 1.25s ease-out infinite;
    }
  }

  @keyframes scrollLoop {
    0% {
      top: 0;
      height: 0;
    }
 
    50% {
      top: 0%;
      height: 100%;
    }
  
    100% {
      top: 100%;
      height: 0;
    }
  }
`;
export {
  StyledSliderWrap,
  StyledSliderMain,
  StyledScrollIndicator,
}