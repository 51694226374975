import styled from "styled-components";

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: ${props => props.show ? 1 : 0};
  transition: opacity 0.5s ease-in-out;
  z-index: ${props => props.isOverlayOpen ? 0 : 5};
  .logo {
    position: relative;
    margin: 20px;
    display: block;
    z-index: 4;
    @media screen and (max-width: 1023px){
      margin: 0;
    }
  }
  .nav-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    &.nav-desktop {
      nav {
        position: relative;
        display: flex;
        align-items: center;
        p {
          position: relative;
          margin: 0 15px;
          cursor: pointer;
          font-family: 'Everet Light';
          color: ${ props => props.isWhiteBg ? `#001A72` : `#FFFFFF`};
          opacity: 0.7;
          transition: opacity 0.25s ease-in-out, color 0.25s ease-in-out;
          span {
            position: absolute;
            bottom: -4px;
            width: 0;
            left: 50%;
            height: 1px;
            background-color: ${ props => props.isWhiteBg ? `#001A72` : `#FFFFFF`};
            transform-origin: 50%;
            transition: width 0.25s ease-in-out, left 0.25s ease-in-out ;
          }
          &:hover {
            opacity: 1;
          }
          &.active {
            opacity: 1;
            span {
              width: 100%;
              left: 0%;
              transition: width 0.25s ease-in-out, left 0.25s ease-in-out ;
            }
          }
        }
      }
      @media screen and (max-width: 1023px){
        display: none;
      }
    }
  }
`;

const StyledMenuMobileWrap = styled.div`
  position: fixed;
  top: 0;
  display: none;
  z-index: 5;
  @media screen and (max-width: 1023px){
    display: block;
    width: 100%;
    height: 100%;
    background-color: #001A72;
    transition: transform 0.5s ease-in-out;
    transform: ${ props => 
      props.isMobileMenuOpen ? 
      `translateX(0%)` :
      `translateX(100%)`
    };
  }
  nav {
    @media screen and (max-width: 1023px){
      padding: 66px 40px;
      font-size: 50px;
      line-height: 60px;
      color: #fff;
      text-align: left;
      display: flex;
      flex-direction: column;
      p {
        margin-bottom: 40px;
        opacity: 0.5;
        display: inline-flex;
        font-family: 'Everet Light';
        &.active {
          opacity: 1;
          span {
            border-bottom: 1px solid #fff;
          }
        }
      }
    } 
    @media screen and (max-width: 767px){
      padding: 50px 24px;
    } 
  }
  .cookie-page {
      position: absolute;
      bottom: 33px;
      right: 20px;
      text-align: right;
      color: #fff;
      font-size: 14px;
      line-height: 20px;
      cursor: pointer;
      a {
        color: inherit;
      }
      @media screen and (min-width: 1024px){
        display: none;
      }
    }
`;

const StyledHamburger = styled.div`
  position: absolute;
  width: 44px;
  height: 52px;
  top: 10px;
  right: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-right: 4px;
  display: none;
  z-index: ${ props => props.show ? 6 : 1};
  opacity: ${ props => props.show ? 1 : 0};
  transition: opacity 0.25s ease-in-out;
  @media screen and (max-width: 1023px){
    display: block;
  }
  span {
    position: absolute;
    width: 24px;
    height: 2px;
    top: 50%;
    left: 50%;
    background-color: ${props => (props.isWhiteBg && !props.isMobileMenuOpen) ? `#001A72` : `#FFFFFF`};
    transform-origin: 50%;
    transition: transform 0.25s ease-in-out, background-color 0.25s ease-in-out;
    &:nth-child(1){
      transform: ${ props => 
        props.isMobileMenuOpen ?
        `translateX(-50%) translateY(calc(-50% )) rotate(45deg)` :
        `translateX(-50%) translateY(calc(-50% - 5px))`
      };
    }
    &:nth-child(2){
      transform: ${ props => 
        props.isMobileMenuOpen ?
        `translateX(-50%) translateY(calc(-50% )) rotate(-45deg)` :
        `translateX(-50%) translateY(calc(-50% + 5px))`
        
      };
    }
  }
`;
export {
  StyledHeader,
  StyledMenuMobileWrap,
  StyledHamburger
}