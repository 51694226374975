import styled from "styled-components";

const StyledHeaderVideoChallenge = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
   
  .header-bar {
    position: absolute;
    height: 74px;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    z-index: 3;
    width: 100%;
    background: ${props => props.isPlaying ? 'linear-gradient(180deg, #001a7275 0%, #001a720c 100%)': 'transparent'};
    border-bottom: ${props => props.isPlaying ? 'none' : '1px solid  rgba(255,255,255,0.15)'};
    transition: background 0.25s ease-in-out;
    .back-btn {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 24px;
      cursor: pointer;
      img {
        margin-right: 15px;
      }
      p {
        font-size: 12px;
        line-height: 18px;
        color: #fff;
      }
    }
  }
  .video-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    
    .poster {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 1;
      background: linear-gradient(45deg, #009ee2 32%,#e5007e 75%);
      background-size: 150% 150%;
      background-position: 0% 100%;
      transition: opacity 0.35s cubic-bezier(0.47, 0, 0.745, 0.715);
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      z-index: 2;
      .video-gradient {
        position: relative;
        width: 100%;
        height: 100%;
        
      }
      .play-cta {
        position: relative;
        z-index: 3;
        margin: 40px auto 0 ;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #fff;
        p {
          font-size: 18px;
          line-height: 28px;
          color: #fff;
          padding: 10px 20px;
        }
      }
      h4 {
        margin: 0 auto;
        font-family: 'Everet Light';
        color: #fff;
        font-size: 118px;
        @media screen and (max-width: 1023px) {
          font-size: 70px;
        }
        @media screen and (max-width: 767px) {
          font-size: 50px;
        }
      }
      &.play--video {
        opacity: 0;
        
        img {
          cursor: none;
        }
      }
    }
    .video-frame {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: 1;
      display: flex;
      align-items: center;
      background-color: #000;
      @media screen and (max-width: 767px){
        position: relative;
      }
      iframe {
        position: relative;
        width: 100%;
        max-height: 100%;
      }
    }
  }
  
 
`;

export {
  StyledHeaderVideoChallenge
}