import { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Player from '@vimeo/player';
import {
  goToSlide,
  transitionLoader,
  updateOnBack
} from '../../redux/actions/generalActions';
import { useTranslation, withTranslation } from 'react-i18next';
import {StyledHeaderVideoCompetency} from './StyledHeaderVideoCompetency';

const HeaderVideoCompetency = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const slug = useParams();
  const { t } = useTranslation();
  const vimeoRef = useRef(null);
  const [firstLoad, setFirstLoad] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [player, setPlayer] = useState(null);

  const isTriggerLoad = useSelector((state) => state.general.triggerLoader);
  
  const goBack = () => {
    
    dispatch(transitionLoader(true));
    dispatch(updateOnBack(true));
    
    if(!isTriggerLoad) setTimeout(() => {
      history.push('/');
      
      dispatch(goToSlide(4));
    }, 1000);
  }

  useEffect(() => {
    setTimeout(() => setFirstLoad(false), 0);
    setTimeout(() => setFirstLoad(true), 2250);
    const videoId = t(`competencyPage.videoHero`);
    if (player !== null) {
      
      player.destroy();
      setPlayer(null);
      const vimeoOptions = {
        controls: false,
        url: videoId,
        width: window.innerWidth,
        autoplay: false,
        muted: false
      };
      setPlayer(new Player(vimeoRef.current, vimeoOptions));
      
    }
  }, [slug]);

 useEffect(() => {
  const videoId = t(`competencyPage.videoHero`);
  
  if(player === null && (videoId.includes('https') && videoId !== null) ){

    const vimeoOptions = {
      controls: false,
      url: videoId,
      width: window.innerWidth,
      autoplay: false,
      muted: false
    };
    setPlayer(new Player(vimeoRef.current, vimeoOptions));

  }
 }, [t])

 useEffect(() => {
  if(player !== null) {
    player.on('pause', function() {
      setIsPlaying(false)
    });
  }
 }, [player])

  const togglePlay = useCallback(() => {
    if(isPlaying) player.pause()
    else player.play()
    setIsPlaying(!isPlaying);
    
  }, [isPlaying,player])

  return(
    <StyledHeaderVideoCompetency isLoaded={firstLoad} isPlaying={isPlaying}>
      <div className="header-bar">
        <div className="back-btn" onClick={goBack}>
          <img src="/img/arrow-white-back.svg" />
          <p>COMPETENCY MODEL</p>
        </div>
      </div>
      <div className="video-wrap" >
        <div 
          onClick={togglePlay}
          className={ isPlaying ? "poster play--video" : "poster"} 
        >
          <h4>Competency<br />Model</h4>
          <div className='play-cta'>
            <p>Play video</p>
            <img src="/img/play-cover.svg" />
          </div>
          
        </div>
        <div ref={vimeoRef} className="video-frame"></div>
      </div>
    </StyledHeaderVideoCompetency>
  )
}

export default withTranslation()(HeaderVideoCompetency);