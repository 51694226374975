import { useTranslation, withTranslation } from 'react-i18next';
import { 
  BeliefsWrapper,
  BeliefsCardWrap,
  BeliefsCard
 } from "./StylesBeliefs";
import { useHistory } from 'react-router-dom';
import { transitionLoader } from '../../redux/actions/generalActions';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

const Beliefs = () => {
  const {t, i18n} = useTranslation();
  const history = useHistory()
  const dispatch = useDispatch();
  const isTriggerLoad = useSelector((state) => state.general.triggerLoader);;
  
  const handleChange = useCallback((url) => {
    
    dispatch(transitionLoader(true));

    if(!isTriggerLoad) setTimeout(() => history.push(url), 1000);
    
   }, [isTriggerLoad])


  return(
    <BeliefsWrapper>
      <BeliefsCardWrap>
        <BeliefsCard onClick={() => handleChange('/belief/quest-to-evolve')}>
          <div className="noise"></div>
          <div className="card--text-wrap">
            <h4>quest<br/>to<br/>evolve</h4>
            <div className="subtitle--mask">
              <p>
                {t('beliefsSubt-1')}
              </p>
            </div>
          </div>
        </BeliefsCard>
        <BeliefsCard onClick={() => handleChange('/belief/design-for-value')}>
          <div className="noise"></div>
          <div className="card--text-wrap">
            <h4>design<br/>for<br/>value</h4>
            <div className="subtitle--mask">
              <p>
                {t('beliefsSubt-2')}
              </p>
            </div>
          </div>
        </BeliefsCard>
        <BeliefsCard onClick={() => handleChange('/belief/will-to-achieve')}>
          <div className="noise"></div>
          <div className="card--text-wrap">
            <h4>will<br/>to<br/>achieve</h4>
            <div className="subtitle--mask">
              <p>
                {t('beliefsSubt-3')}
              </p>
            </div>
          </div>
        </BeliefsCard>
        
        <BeliefsCard onClick={() => handleChange('/belief/care-for-balance')}>
          <div className="noise"></div>
          <div className="card--text-wrap">
            <h4>care<br/>for<br/>balance</h4>
            <div className="subtitle--mask">
              <p>
                {t('beliefsSubt-4')}
              </p>
            </div>
          </div>
        </BeliefsCard>
      </BeliefsCardWrap>
    </BeliefsWrapper>
  )
}

export default withTranslation()(Beliefs);