import {useEffect, useState} from 'react';
import {ContentBelief} from './StylesContentBeliefPage';
import { useParams } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';

const ContentBeliefPage = () => {
  const [firstLoad, setFirstLoad] = useState(false);
  const slug = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    //setTimeout(() => setFirstLoad(false), 0);
    setTimeout(() => setFirstLoad(true), 1000);
  }, [slug])

  const toggleAccordion = (event) => {
    const prevActive = document.querySelector('.accordion-wrap.active');
    const parent = event.currentTarget;
    const innerMask = parent.querySelector('.accordion-list-mask');
    const innerTextHeight = innerMask.querySelector('ul').clientHeight;
    if (parent.classList.contains('active')) {

      parent.classList.remove('active');
      innerMask.style.height = 0;

    } else {
      if (prevActive !== undefined && prevActive !== null) {
        const activeMask = prevActive.querySelector('.accordion-list-mask')
        prevActive.classList.remove('active');
        activeMask.style.height = 0;
      }

      innerMask.style.height = `${innerTextHeight}px`;
      parent.classList.add('active');

    }

  }


  return(
    <ContentBelief isLoaded={firstLoad}>
      {/* <div className="ifame-wrap">
        <iframe src="https://player.vimeo.com/video/617830825" 
          width="100%" height="auto" frameBorder="0" allow="autoplay; fullscreen">  
        </iframe>
        <div className="iframe-reveal"></div>
      </div> */}
      <div className="bullets-beliefs">

        <div className='accordion-wrap' onClick={toggleAccordion}>
          <div className='accordion-row'>
            <div className="plus-indicator">
              <span></span>
              <span></span>
            </div>
            <p dangerouslySetInnerHTML={{
              __html: t(`beliefsPage.${slug.id}.beliefsBullets-01`)
            }}></p>
          </div>
          <div className='accordion-list-mask'>
            <ul className='accordion-list'>
              <li dangerouslySetInnerHTML={{
                __html: t(`beliefsPage.${slug.id}.beliefsList-01`)
              }}></li>
              <li dangerouslySetInnerHTML={{
                __html: t(`beliefsPage.${slug.id}.beliefsList-02`)
              }}></li>
              <li dangerouslySetInnerHTML={{
                __html: t(`beliefsPage.${slug.id}.beliefsList-03`)
              }}></li>
            </ul>
          </div>
        </div>
        
        <div className='accordion-wrap' onClick={toggleAccordion}>
          <div className='accordion-row'>
            <div className="plus-indicator">
              <span></span>
              <span></span>
            </div>
            <p dangerouslySetInnerHTML={{
              __html: t(`beliefsPage.${slug.id}.beliefsBullets-02`)
            }}></p>
          </div>
          <div className='accordion-list-mask'>
            <ul className='accordion-list'>
              <li dangerouslySetInnerHTML={{
                __html: t(`beliefsPage.${slug.id}.beliefsList-04`)
              }}></li>
              <li dangerouslySetInnerHTML={{
                __html: t(`beliefsPage.${slug.id}.beliefsList-05`)
              }}></li>
              <li dangerouslySetInnerHTML={{
                __html: t(`beliefsPage.${slug.id}.beliefsList-06`)
              }}></li>
            </ul>
          </div>
        </div>

        <div className='accordion-wrap' onClick={toggleAccordion}>
          <div className='accordion-row'>
            <div className="plus-indicator">
              <span></span>
              <span></span>
            </div>
            <p dangerouslySetInnerHTML={{
              __html: t(`beliefsPage.${slug.id}.beliefsBullets-03`)
            }}></p>
          </div>
          <div className='accordion-list-mask'>
            <ul className='accordion-list'>
              <li dangerouslySetInnerHTML={{
                __html: t(`beliefsPage.${slug.id}.beliefsList-07`)
              }}></li>
              <li dangerouslySetInnerHTML={{
                __html: t(`beliefsPage.${slug.id}.beliefsList-08`)
              }}></li>
              <li dangerouslySetInnerHTML={{
                __html: t(`beliefsPage.${slug.id}.beliefsList-09`)
              }}></li>
            </ul>
          </div>
        </div>
      </div>
    </ContentBelief>
  )
}

export default withTranslation()(ContentBeliefPage);