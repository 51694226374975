import styled from "styled-components";

const ContentBelief = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  .ifame-wrap {
    position: relative;
    width: 70%;
    top: -90px;
    margin: 0 auto 10px;
    overflow: hidden;
    @media screen and (max-width: 1023px){
      top: -70px;
      width: calc(100% - 80px);
      margin: 0 auto 10px;
    }
    @media screen and (max-width: 767px){
      top: -50px;
      width: calc(100% - 48px);
      margin: 0 auto 10px;
    }
    iframe {
      position: relative;
      display: block;
      width: 100%;
      opacity: ${ props => props.isLoaded ? `1` : `0`};
      transition: opacity 0s 1s;
      height: calc(39.25vw);
      @media screen and (max-width: 1023px){
        height: calc(54.25vw);
      }
      @media screen and (max-width: 767px){
        height: calc(52.25vw);
      }
    }
    .iframe-reveal {
      position: absolute;
      z-index: 2;
      background-color: #D9DDEA;
      bottom: ${ props => props.isLoaded ? `100%` : `0%`};
      height: ${ props => props.isLoaded ? `100%` : `0%`};
      width: 100%;
      transition: height 1s cubic-bezier(0.87, 0, 0.13, 1), bottom 0.5s cubic-bezier(0.87, 0, 0.13, 1) ${ props => props.isLoaded ? `1.25s` : `0s`};

    }
  }
  .bullets-beliefs {
    position: relative;
    width: 50%;
    margin: 50px auto 110px;
    opacity: ${ props => props.isLoaded ? 1 : 0};
    transition: opacity 0.5s cubic-bezier(0.87, 0, 0.13, 1);
    @media screen and (max-width: 1023px){
      width: calc(100% - 40px);
      margin: 0 auto 40px;
    }
    @media screen and (max-width: 767px){
      width: calc(100% - 24px);
      margin: 0 auto 40px;
    }
    .accordion-wrap {
      position: relative;
      width: 100%;
      padding: 40px 0;
      border-bottom: 1px solid #D9DDEA;
      text-align: left;
      font-family: 'Everet Light';
      overflow: hidden;
      cursor: pointer;
      .plus-indicator {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 44px;
        height: 44px;
        transition: transform 0.5s ease-in-out;
        span {
          position: absolute;
          width: 24px;
          height: 2px;
          background-color: #001A72;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          &:nth-child(2){
            transform: translate(-50%, -50%) rotate(90deg);
          }
        }
      }
      &.active {
        .plus-indicator {
          transform: translateY(-50%) rotate(135deg);
        }
      }
      &:nth-child(3) {
        border-bottom: none;
      }
      .accordion-list-mask {
        position: relative;
        width: 100%;
        overflow: hidden;
        height: 0;
        transition: height 0.25s ease-in-out;
      }
      ul {
        margin: 0;
        padding: 40px 0 0;
        list-style-type: square;
        li {
          font-size: 18px;
          line-height: 30px;
          margin-bottom: 18px;
          font-family: 'Everet Regular';
          list-style-position: inside;
          color: #001A72;
          @media screen and (max-width: 767px){
            font-size: 16px;
          }
        }
      }
    }
    .accordion-row {
      position: relative;
      width: 100%;
      p {
        color: #001A72;
        font-size: 35px;
        line-height: 48px;
        width: calc(100% - 60px);
      }
    }
  }
`;

export {
  ContentBelief
}