
import styled from "styled-components";

const CompetencyWrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding-bottom: 80px;
  @media screen and (max-width: 1023px){
    margin: 0;
    align-items: flex-start;
  }
  #competency--overlay-video {
    position: fixed;
    height: 100vh;
    width: 100vw;
    transform: translateY(100%);
    background-color: #001A72;
    z-index: 100;
    top: 0;
    left: 0;
    transition: transform 0.25s ease-in;
    display: flex;
    align-items: center;
    &.show {
      transform: translateY(0%);
    }
    .close--overlay {
      position: absolute;
      width: 44px;
      height: 52px;
      top: 24px;
      right: 24px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      margin-right: 4px;
      z-index: 6;
      background-color: rgba(0, 0, 0, 0.2);
      cursor: pointer;
      @media screen and (max-width: 1023px){
        top: 24px;
        right: 24px;
      }
      span {
        position: absolute;
        width: 24px;
        height: 2px;
        top: 50%;
        left: 50%;
        background-color: #FFFFFF;
        transform-origin: 50%;
        &:nth-child(1){
          transform: translateX(-50%) translateY(calc(-50% )) rotate(45deg);
        }
       
        &:nth-child(2){
          transform: translateX(-50%) translateY(calc(-50% )) rotate(-45deg);
        }
      }
    }
    .video-wrap {
      position: relative;
      width: 100%;
      height: 100%;
      @media screen and (max-width: 767px){
        height: auto;
      }
      .poster {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-size: cover;
        background-position: 50%;
        opacity: 1;
        transition: opacity 0.35s cubic-bezier(0.47, 0, 0.745, 0.715);
        display: flex;
        align-items: center;
        text-align: center;
        z-index: 2;
        img {
          margin: 0 auto;
          cursor: pointer;
        }
        &.play--video {
          opacity: 0;
          
          img {
            cursor: none;
          }
        }
      }
      .video-frame {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
        z-index: 1;
        display: flex;
        align-items: center;
        background-color: #000;
        @media screen and (max-width: 767px){
          position: relative;
        }
        iframe {
          position: relative;
          width: 100%;
          max-height: 100%;
        }
      }
    }
  }
`;

const CompetencyCardWrap = styled.div`
  position: relative;
  display: flex;
  padding: 0 44px;
  align-items: center;
  justify-content: space-between;
  height: 60%;
  width: 100%;
  @media screen and (max-width: 1024px){
    flex-wrap: wrap;
    padding: 80px 20px 0;
    min-height: calc(100% - 100px);
    align-items: flex-start;
  }
  @media screen and (max-width: 767px){
    padding: 0 10px 0;
    overflow: hidden;
    overflow-x: scroll;
    min-height: 80%;
    height: auto;
    flex-wrap: nowrap;
  }
`;

const CompetencyCard = styled.div`
  position: relative;
  align-self: stretch;
  flex: 0 0 24%;
  max-width: 24%;
  cursor: pointer;
  @media screen and (max-width: 1024px){
    flex: 0 0 48%;
    max-width: 48%;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 767px){
    flex: 0 0 85%;
    max-width: 85%;
    margin-right: 4%;
    margin-bottom: 0;
  }
  a {
    text-decoration: none;
  }
  
  .noise {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    transform: scale(1);
    transition: transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
    .hover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      background-color: rgba(0, 26, 114, 0.15);
      z-index: 2;
      transition: background-color 0.5s cubic-bezier(0.16, 1, 0.3, 1);
      img {
        width: 30px;
        margin: 0 auto;
      }
    }
    .row--background {
      display: block;
      width: 100%;
    }
    &:hover{
      transform: scale(1.05);
      @media screen and (max-width: 1023px){
        transform: scale(1);
      }
      .hover {
        background-color: transparent;
      }
    }
  }
  .card--text-wrap {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  p{
    font-size: 45px;
    line-height: 50px;
    text-transform: uppercase;
    text-align: center;
    font-family: 'Everet Light';
    margin: 24px auto 0;
    color: #001A72;
    @media screen and (max-width: 1400px){
      font-size: 30px;
      line-height: 38px;
    } 
    @media screen and (max-width: 1024px){
      font-size: 38px;
      line-height: 45px;
    }
  }


`;

export {
  CompetencyWrapper,
  CompetencyCardWrap,
  CompetencyCard
}