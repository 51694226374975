import styled from "styled-components";

const StoryPageStyles = styled.section`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  .story-slider {
    padding-right: 40px;
    margin-bottom: 110px;
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
    @media screen and (max-width: 1023px){
      padding-right: 20px;
    }
    .story--slide {
      &:first-child {
        margin-left: 40px;
        @media screen and (max-width: 1023px){
          margin-left: 20px;
        }
      }
      img {
        display: block;
        width: 100%;
      }
    }
  }
`;

export {
  StoryPageStyles
}