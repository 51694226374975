import styled from "styled-components";

const StyledHeaderBeliefPage = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  background: linear-gradient(45deg, #009ee2 32%,#e5007e 75%);
  background-size: 150% 150%;
  background-position: 0% 100%;
  animation: gradientLoop 8s ease-in-out infinite;
  .grain {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-size: cover;
    background-image: url('/img/noise.png');
  }
  .header-bar {
    position: relative;
    width: 100%;
    height: 74px;
    display: flex;
    align-items: center;
    z-index: 2;
    border-bottom: 1px solid rgba(255,255,255,0.15);
    .back-btn {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 24px;
      cursor: pointer;
      img {
        margin-right: 15px;
      }
      p {
        font-size: 12px;
        line-height: 18px;
        color: #fff;
      }
    }
  }
  .title-wrap {
    position: relative;
    width: 50%;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    padding: 100px 0 160px;
    z-index: 2;
    @media screen and (max-width: 1024px){
      width: calc(100% - 80px);
    }
    @media screen and (max-width: 767px){
      padding: 70px 0 100px;
    }
    .mask-text {
      position: relative;
      width: 100%;
      overflow: hidden;
      margin-bottom: 18px;
      h2 {
        position: relative;
        font-size: 58px;
        line-height: 66px;
        margin: 0 auto;
        font-family: 'Everet Light';
        text-transform: uppercase;
        transform: ${ props => props.isLoaded? `translateY(0%)`: `translateY(100%)`};
        transition: transform 0.25s cubic-bezier(0.3,0.2,0.1, 0.01);
        @media screen and (max-width: 767px){
          font-size: 40px;
          line-height: 46px;
        }
      }
      p {
        position: relative;
        font-size: 22px;
        transform: ${ props => props.isLoaded? `translateY(0%)`: `translateY(100%)`};
        transition: transform 0.25s cubic-bezier(0.3,0.2,0.1, 0.01);
      }
    }
    
  }
  @keyframes gradientLoop {
    0% {
      background-position: 0% 100%;
    }
    50% {
      background-position: 100% 0%;
    }
    100% {
      background-position: 0% 100%;
    }
  }
`;

export {
  StyledHeaderBeliefPage
}