import * as THREE from 'three'
import { extend } from '@react-three/fiber'
import { shaderMaterial } from '@react-three/drei'
import glsl from 'babel-plugin-glsl/macro'


const GradientMaterial = shaderMaterial (
		//uniforms
		{
			u_randomisePosition: new THREE.Vector2(1, 2),
			u_bg: new THREE.Color(0.1),
			u_bgMain: new THREE.Color(0.1),
			u_color1: new THREE.Color(0.1),
			u_color2: new THREE.Color(0.1),
		},
		//vertex
		glsl`
		precision lowp float;
    varying vec2 vUv;

		void main() {
				vUv = uv;
				
				gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
		}
		`
		,
		glsl`
		#pragma glslify: cnoise = require(glsl-noise/classic/2d);
		precision lowp float;

    uniform vec3 u_color1;
		uniform vec3 u_color2;
		uniform vec3 u_bg;
		uniform vec3 u_bgMain;
		uniform vec2 u_randomisePosition;

    varying vec2 vUv;
		float xDistortion;

		vec3 color;

    void main() {
      xDistortion = cnoise(vUv.xx * 0.6 - u_randomisePosition * 0.01);
		
			float noise1 = cnoise(vUv + u_randomisePosition * 0.4);
			float noise2 = cnoise(vUv + u_randomisePosition * 0.6);

			vec3 color = u_bgMain;
			color = mix(color, u_bg, vUv.y - sin(vUv.y * 0.01 - noise1));
			color = mix(color, u_color2, 0.5 - xDistortion);
			
			float border = smoothstep(0.9, sin(0.1 * 0.2), vUv.y * 0.1);
			color = mix(color, u_color1, 0.7 -  cos(noise2 - border));	
			gl_FragColor = vec4(color, 1.8);
    }
		`
)

// This is the 🔑 that HMR will renew if this file is edited
// It works for THREE.ShaderMaterial as well as for drei/shaderMaterial
// GradientMaterial.key = guid.generate()
// Make the material available in JSX as <GradientMaterial />
extend({ GradientMaterial })

export {GradientMaterial};
