import styled from "styled-components";

const StyledOverlayManifesto = styled.div`
  position: fixed;
  bottom: 0;
  height: 100%;
  width: 100%;
  left: 0;
  z-index: ${props => props.show ? 5 : 0 };
  color: #ffffff;
  opacity: ${props => props.show ? 1 : 0 };
  transform: ${props => props.show ? `translateY(0%)` : `translateY(100%)` };
  transition: transform 0s ease-in-out ${props => props.show ? '0.15s' : '0s'}, opacity 0.25s ease-in-out ${props => props.show ? '0.15s' : '0s'};
  overflow: hidden;
  .full-manifesto {
    position: relative;
    padding: 140px 176px;
    font-size: 45px;
    line-height: 50px;
    font-family: 'Everet Light';
    @media screen and (max-width: 1023px) {
      padding: 60px 40px;
      font-size: 38px;
      line-height: 44px;
    }
    @media screen and (max-width: 767px) {
      padding: 60px 24px;
      font-size: 30px;
      line-height: 35px;
    }
  }
  .close-manifesto {
    position: absolute;
    top: 25px;
    right: 40px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.25s ease-in-out;
    z-index: 5;
    img {
      display: block;
    }
    &:hover {
      opacity: 0.7;
    }
    @media screen and (max-width: 1023px) {
      top: 17px;
      right: 17px;
      width: 44px;
      img {
        width: 100%;
      }
    }
  }
`;

export {
  StyledOverlayManifesto
}