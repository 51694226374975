import React, { useState, useEffect, useRef, Suspense } from 'react'
import Player from '@vimeo/player';
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, withTranslation } from 'react-i18next';
import { toggleOverlay } from "../../redux/actions/generalActions";
import { toggleManifesto } from "../../redux/actions/generalActions";

import { 
  StyledVimeoPlayerWrap,
  StyledVideoControls,
  StyledVimeoPlayer,
  StyledVideoClose,
} from './StylesVimeoPlayer';

const VimeoPlayerComponent = ({ videoId, isActive, hasCta }) => {
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  const isOverlayOpen = useSelector((state) => state.general.isOverlayOpen);
  const vimeoRef = useRef(null);
  const [player, setPlayer] = useState(null);
  const [playerReady, setPlayerReady] = useState(false);
  const [isPlaying, setPlay] = useState(true);
  const [isMute, setMute] = useState(true);
  const [timeUpdate, setTimeUpdate] = useState(0);
  const [isFull, setIsFull] = useState(false);
  const [currentId, setCurrentId] = useState(0)

  useEffect(() => {
    if(videoId !== currentId && player !== null) {
      player.destroy();
      setPlayer(null);
      setPlay(false);
      setPlayerReady(false);
      setTimeout( () => {
        player.off('timeupdate');
        setTimeUpdate(0)
      }, 500)
    }

    if(player === null && (!(typeof videoId === 'string') && videoId !== null)){

      const vimeoOptions = {
        controls: false,
        id: videoId,
        width: window.innerWidth,
        autoplay: true,
        muted: true
      };
      setCurrentId(videoId)
      setPlayer(new Player(vimeoRef.current, vimeoOptions));

    }

    
  }, [vimeoRef, player, playerReady, videoId, currentId]);

  useEffect(() => {

    if(player !== null) {

      player.on('loaded', function() {
        if(!playerReady) setPlayerReady(true)
      });

      player.on('ended', function() {
        setPlay(false)
      });

      player.on('pause', function() {
        setPlay(false)
      });

      if(isActive && playerReady){
        player.play();
        updateProgress();
        setPlay(true);
        
      } else {
        
        if(playerReady) {
          player.pause();
          setPlay(false)
        }
      }
    }
  }, [playerReady, player, isActive ]);

  useEffect(() => {
    
    if(isFull && !isOverlayOpen){
      setIsFull(false);
    }

  }, [isFull, isOverlayOpen])

  const togglePlay = () => {

    if(isPlaying) {
      player.pause()
      setPlay(false)
    }
    else{ 
      player.play()
      setPlay(true)
    }
  }

  const toggleMute = () => {
    player.setMuted(!isMute).then(function(muted) {
      setMute(muted)
    }).catch(function(error) {
        // an error occurred
    });
  }

  const updateProgress = () => {
    player.on('timeupdate', function(data) {
      // data is an object containing properties specific to that event
      setTimeUpdate(data.percent * 100)
    });
  }

  const toggleFullBrowser = () => {
    setIsFull(!isFull);
    dispatch(toggleOverlay(!isFull))
  }

  const openManifesto = () => {
    dispatch(toggleManifesto(true));
  }

  return (
    

    <StyledVimeoPlayerWrap className={isFull ? 'full-screen' : ''}> 
      
      {
        videoId !== null &&
        <>
          <StyledVimeoPlayer ref={vimeoRef} className={isFull ? 'full-screen ' : ''}/>
          
          <StyledVideoControls withCta={hasCta}>
            <div className="toggle-overlay" onClick={togglePlay}></div>
            <div className={`play-toggle ${isPlaying ? 'playing' : ''}`} onClick={togglePlay}>
              <div className="play">
                <img alt="play icon" src="/img/play-icon.svg" />
              </div>
              <div className="pause">
                <img alt="pause icon" src="/img/pause-icon.svg" />
              </div>
            </div>
            <div className="load-time">
              <div className="loaded-time" style={{width: `${timeUpdate}%`}}></div>
            </div>
            <div className="controls-wrap" style={{flex: isFull ? '0 0 50px' : '0 0 100px' }}>
              <div className="full-screen" 
                onClick={toggleFullBrowser} 
                style={{display: isFull ? 'none' : 'flex' }}
              >
                <img alt="full screen icon" src="/img/full-screen.svg" />
              </div>
              <div className={`audio-toggle ${isMute ? 'is-unmute' : ''}`} onClick={toggleMute}>
                <div className="unmute">
                  <img alt="unmute icon" src="/img/unmute.svg" />
                  <img alt="unmute icon" className="mobile" src="/img/unmute-mobile.svg" />
                </div>
                <div className="mute" >
                  <img alt="mute icon" src="/img/mute.svg" />
                  <img alt="mute icon" className="mobile" src="/img/mute-mobile.svg" />
                </div>
                
              </div>
              {
                hasCta &&
                <div className="cta--manifesto" onClick={openManifesto}>
                  { t('manifestoCta')}
                </div>
              }
            </div>
            <StyledVideoClose 
              onClick={toggleFullBrowser} 
              style={{display: isFull ? 'block' : 'none' }}
            >
              <img alt="close icon" src="/img/close-video.svg" />
            </StyledVideoClose>

          </StyledVideoControls>
      </>
      }
      
      {
        !playerReady &&
        <div className="load--video">

        </div>
      }
      
    </StyledVimeoPlayerWrap>  
  )
}
export default withTranslation()(VimeoPlayerComponent);
