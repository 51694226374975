import styled from "styled-components";

const StoriesWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 20px;
  height: calc(100% - 40px);
  background-color: #fff;
  display: flex;
  align-items: center;
  overflow: hidden;
  cursor: pointer; 
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
  &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
  }
  @media screen and (max-width: 1023px){
    margin: 0;
    height: 100%;
    align-items: flex-start;
  }
  .swiper-slide-active {
    >div {
      transform: scale(1);
    }
  }
  .swiper-slide-active {
    .slide--info-wrap {
      .slide-excerpt {
        opacity: 1;
        transition: opacity 0.5s ease-in-out 0.15s;
      }
      .slide-author {
        opacity: 1;
        transition: opacity 0.5s ease-in-out 0.25s;
      }
    }
  }
`;
const SlideWrap = styled.div`
  position: relative;
  width: 100%;
  height: 80%;
  text-align: center;
  transform: scale(0.95);
  transition: all 0.5s ease-in-out;
  
  @media screen and (max-width: 1023px){
    height: 50%;
    left: inherit !important;
    right: inherit !important;
  }
  @media screen and (max-width: 767px){
    height: 70%;
  }
  .slide--img {
    position: relative;
    width: 100%;
    overflow: hidden;
    max-height: 80%;
    @media screen and (max-width: 767px){
      height: 60%;
    }
    img {
      display: block;
      height: auto;
      width: 100%;
      @media screen and (max-width: 767px){
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 100%;
        width: auto;
      }
    }
  }
  .slide--info-wrap {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    color: #001A72;
    margin-top: 30px;
    @media screen and (max-width: 768px){
      flex-direction: column;
    }
    .slide-excerpt {
      flex: 0 0 65%;
      opacity: 0;
      transition: opacity 0.5s ease-in-out 0s;
      h4 {
        font-weight: 400;
        font-size: 26px;
        line-height: 32px;
        text-align: left;
        margin: 0 auto;
        padding: 0;
        font-family: 'Everet Light';
        @media screen and (max-width: 768px){
          font-size: 22px;
          line-height: 28px;
        }
      }
      @media screen and (max-width: 768px){
        flex: 0 0 100%;
      }
    }
    .slide-author {
      flex: 0 0 30%;
      font-size: 14px;
      line-height: 17px;
      text-align: left;
      opacity: 0;
      transition: opacity 0.5s ease-in-out 0s;
      @media screen and (max-width: 768px){
        flex: 0 0 100%;
        margin-top: 20px;
      }
      .author {
        margin-bottom: 5px;
      }
    }
    .author-role {
      font-family: 'Everet Light';
    }
  }
`;


export {
  StoriesWrapper,
  SlideWrap,
}