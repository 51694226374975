import React, { useEffect, useState } from "react";
import { Background, SliderWrap, Header } from "../../components";

const Home = () => {
  console.log('Bolton culture webdev3 🤠')

  return(
    <React.Fragment>
      <Header />
      <Background />
      <SliderWrap />
    </React.Fragment>
  )
}

export default Home;
