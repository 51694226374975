import React, { useEffect, useRef, useState } from "react";
import {
  HeaderBeliefPage, 
  ContentBeliefPage,
  LinkBelief
} from '../../components';
import { BeliefSection } from './StylesBeliefSection';
import { useLocation } from "react-router";
import Scrollbar from 'smooth-scrollbar';

const BeliefPage = () => {
  const parent = useRef(null);
  const location = useLocation()
  const [scrollbar, setScrollBar] = useState(null);

  useEffect(() => {
    if(scrollbar !== null){
      scrollbar.scrollTop = 0;
    }
   
  }, [location, scrollbar])

  useEffect(() =>{
    const options = {
      damping: 0.075
    }
    if(parent.current !== null){
      setScrollBar(Scrollbar.init(parent.current, options))
      
    }
  }, [parent])

  return(
    <BeliefSection ref={parent}>
      <HeaderBeliefPage />
      <ContentBeliefPage />
      <LinkBelief />
    </BeliefSection>
  )
}

export default BeliefPage;