import { useCallback, useEffect, useState, useRef } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import Player from '@vimeo/player';
import {
  CompetencyWrapper,
  CompetencyCardWrap,
  CompetencyCard
} from './StylesCompetencyOverlayRow'


const CompetencyOverlayRow = () => {
  const overlay = useRef();
  const [slug, setSlug] = useState('');
  const { t } = useTranslation();
  const vimeoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [player, setPlayer] = useState(null);
  
  useEffect(() => {

    const videoId = t(`competencyPage.${slug}`);
    
    if(player !== null && slug !== '') {
      player.destroy();
      setPlayer(null);
      const vimeoOptions = {
        controls: false,
        url: videoId,
        width: window.innerWidth,
        autoplay: false,
        muted: false
      };
      setPlayer(new Player(vimeoRef.current, vimeoOptions));
    
    } else {
      

      const vimeoOptions = {
        controls: false,
        url: videoId,
        width: window.innerWidth,
        autoplay: false,
        muted: false
      };
      setPlayer(new Player(vimeoRef.current, vimeoOptions));

    
    }
  }, [slug]);


  useEffect(() => {
    if(player !== null) {
      player.on('pause', function() {
        setIsPlaying(false)
      });
    }
  }, [player])

  const togglePlay = useCallback(() => {
    
    if(isPlaying) player.pause()
    else player.play()
    setIsPlaying(!isPlaying);
    
  }, [isPlaying, player])
  
  function handleOverlay(param) {
    if (param) {
      
      setSlug(param)
      setTimeout(() => overlay.current.classList.add('show'), 300)
      
    }
    else {
      overlay.current.classList.remove('show')
      setTimeout(() => setSlug(''), 300)
      if (isPlaying) {
        player.pause()
        setIsPlaying(false);
      }
    }
  }

  return (
     <CompetencyWrapper>
      <CompetencyCardWrap>
        <CompetencyCard onClick={() => handleOverlay('belief-01-video')}>
          <div className="noise">
            <div className='hover'>
              <img src="/img/play-cover.svg" />
            </div>
            <img className='row--background' src='/img/competency-grid/model-grid-01.jpg' />
          </div>
          <div className="card--text-wrap">
            <p className='body-copy'>Quest<br/>to<br/>evolve</p>
            
          </div>
        </CompetencyCard>
        <CompetencyCard onClick={() => handleOverlay('belief-02-video')}>
          <div className="noise">
            <div className='hover'>
              <img src="/img/play-cover.svg" />
            </div>
            <img className='row--background' src='/img/competency-grid/model-grid-02.jpg' />
          </div>
          <div className="card--text-wrap">
            <p className='body-copy'>Design<br/>for<br/>value</p>
            
          </div>
        </CompetencyCard>
        <CompetencyCard onClick={() => handleOverlay('belief-03-video')}>
          <div className="noise">
            <div className='hover'>
              <img src="/img/play-cover.svg" />
            </div>
            <img className='row--background' src='/img/competency-grid/model-grid-03.jpg' />
          </div>
          <div className="card--text-wrap">
            <p className='body-copy'>Will<br/>to<br/>achieve</p>
            
          </div>
        </CompetencyCard>
        
        <CompetencyCard onClick={() => handleOverlay('belief-04-video')}>
          <div className="noise">
            <div className='hover'>
              <img src="/img/play-cover.svg" />
            </div>
            <img className='row--background' src='/img/competency-grid/model-grid-04.jpg' />
          </div>
          <div className="card--text-wrap">
            <p className='body-copy'>Care<br/>for<br/>balance</p>
            
          </div>
        </CompetencyCard>
      </CompetencyCardWrap>
      <div id="competency--overlay-video" ref={overlay} >
        <div className='close--overlay' onClick={() => handleOverlay()}>
          <span></span>
          <span></span>
        </div>
        <div className="video-wrap" >
          <div 
              onClick={togglePlay}
              className={isPlaying ? "poster play--video" : "poster"} 
              
          >
            <img src="/img/play-cover.svg" />
          </div>
          <div ref={vimeoRef} className="video-frame"></div>
        </div>
      </div>
    </CompetencyWrapper>
  )
}

export default withTranslation()(CompetencyOverlayRow)