import styled from "styled-components";

const LinkBeliefWrap = styled.div`
  position: relative;
  width: 100%;
  border-top: 1px solid #D9DDEA;
  padding-top: 70px;
  padding-bottom: 130px;
  text-align: center;
  h6 {
    margin: 0 auto;
    color: rgb(0 26 114 / 70%);;
    text-transform: uppercase;
    padding-bottom: 60px;
  }
  .cards-wrap {
    position: relative;
    width: 80%;
    min-height: 90vh;
    display: flex;
    align-items: center;
  }
`;

const LinkCardWrap = styled.div`
  position: relative;
  display: flex;
  padding: 0 44px;
  align-items: center;
  justify-content: space-between;
  min-height: 60vh;
  width: 80%;
  margin: 0 auto;
  @media screen and (max-width: 1023px){
    padding: 80px 0 0 24px;
    width: calc(100% - 24px);
    overflow-x: scroll;
    align-items: flex-start;
  }
`;

const LinkCard = styled.div`
  position: relative;
  align-self: stretch;
  flex: 0 0 32%;
  max-width: 32%;
  background: linear-gradient(45deg, #009ee2 32%,#e5007e 75%);
  background-size: 150% 150%;
  background-position: 0% 100%;
  transform: scale(1);
  transition: background-position 0.95s cubic-bezier(0.16, 1, 0.3, 1), transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  cursor: pointer;
  
  &::last-child {
    margin-right: 24px;
  }
  @media screen and (max-width: 1023px){
    flex: 0 0 85%;
    max-width: 85%;
    margin-right: 4%;
  }
  a {
    text-decoration: none;
  }
  &:hover{
    background-position: 50% 0%;
    transform: scale(1.05);
    @media screen and (max-width: 1023px){
      background-position: 0% 100%;
      transform: scale(1);
    }
  }
  .noise {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url('/img/noise-cards.png');
    background-size: cover;
  }
  .card--text-wrap {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  h4 {
    color: #fff;
    font-size: 45px;
    line-height: 50px;
    text-transform: uppercase;
    padding: 40px 35px;
    text-align: center;
    font-family: 'Everet Light';
    margin: 0 auto;
    transition: margin 0.25s ease-in-out;
    @media screen and (max-width: 1400px){
      font-size: 30px;
      line-height: 38px;
    } 
    @media screen and (max-width: 1024px){
      padding: 40px 35px 20px;
      font-size: 38px;
      line-height: 45px;
    }
  }
  .subtitle--mask {
    position: absolute;
    color: #fff;
    padding: 0 35px;
    text-align: center;
    overflow: hidden;
    margin-top: 100px;
    p {
      position: relative;
      font-size: 18px;
      line-height: 24px;
      transform: translateY(100%);
      transition: transform 0.75s cubic-bezier(0.16, 1, 0.3, 1) 0s;
    }
    @media screen and (max-width: 1024px){
      position: relative;
      margin-top: 50px;
      p {
        transform: translateY(0%);
      }
    }
  }
  &:hover {
    h4 {
      margin-bottom: 200px;
      @media screen and (max-width: 1024px){
        margin-bottom: 0;
      }
    }
    .subtitle--mask {
      p {
        transform: translateY(0%);
        transition: transform 0.75s cubic-bezier(0.16, 1, 0.3, 1) 0.20s;
      }
    }
  }
  `;

export {
  LinkBeliefWrap,
  LinkCardWrap,
  LinkCard
}