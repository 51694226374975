import { useDispatch, useSelector } from 'react-redux';
import {
  toggleMenuMobile
} from '../../redux/actions/generalActions';

import {
  StyledHamburger
} from './StylesHeader';

const Hamburger = ({isWhiteBg}) => {
  const dispatch = useDispatch()
  const isMobileMenuOpen = useSelector((state) => state.general.isMenuMobileOpen);
  const isShowingMenu = useSelector((state) => state.general.isShowingMenu);
  const isManifestoOpen = useSelector((state) => state.general.isManifestoOpen);

  const toggleMenuMobileFn = () => {
    dispatch(toggleMenuMobile(!isMobileMenuOpen))
  }

  return(
    <StyledHamburger
      show={isShowingMenu && !isManifestoOpen} 
      isMobileMenuOpen={isMobileMenuOpen} 
      onClick={toggleMenuMobileFn}
      isWhiteBg={isWhiteBg}
    >
      <span></span>
      <span></span>
    </StyledHamburger>
  )
}

export default Hamburger;