import styled from "styled-components";

const StyledPrivacyContent = styled.section`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  #back-home {
    position: absolute;
    top: 0px;
    left: 0px;
    cursor: pointer;
    padding: 20px;
    background-color: #fff;
    @media screen and (max-width: 1023px){
      padding: 0;
    }
  }
  .content {
    padding: 100px 20px 40px;
    max-width: 50%;
    margin: 0 auto;
    @media screen and (max-width: 1024px){
      max-width: calc(100% - 40px);
    }
    @media screen and (max-width: 767px){
      max-width: calc(100% - 20px);
    }
    a {
      color: #E5007E;
    }
  }
  h1 {
    font-size: 58px;
    line-height: 66px;
    @media screen and (max-width: 767px){
      font-size: 40px;
      line-height: 46px;
    }
  }
  h2 {
    font-size: 40px;
    line-height: 46px;
    @media screen and (max-width: 767px){
      font-size: 35px;
      line-height: 48px;
    }
  }
  h3 {
    font-size: 25px;
    line-height: 35px;
    @media screen and (max-width: 767px){
      font-size: 20px;
      line-height: 28px;
    }
  }
`;
export {
  StyledPrivacyContent
}