import { useEffect, useRef, useState, useCallback } from "react";
import {
  HeaderStoryPage,
  ContentStoryPage,
  NextStoryLink,
  InteractiveCursor
} from '../../components';
import {
  StoryPageStyles
} from './StoryPageStyled';
import { useParams } from 'react-router-dom';
import {slides} from '../../data/story-slides';
import { Swiper, SwiperSlide } from 'swiper/react';

const StoryPage = () => {
  const parent = useRef(null);

  return (
    <StoryPageStyles ref={parent}>
      <HeaderStoryPage />
      <ContentStoryPage />
      <NextStoryLink />
    </StoryPageStyles>
  )
}

export default StoryPage;