import styled from "styled-components";

const StyledCurtainLoadWrap = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 0;
  background-color: #001A72;
  transition: height 0s, bottom 0s;
  z-index: 10;
  display: flex;
  align-items: center;
  text-align: center;
  overflow: hidden;
  p {
    margin: 0 auto;
    font-size: 18px;
    background: linear-gradient(45deg, #009ee2 25%,#e5007e 75%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: uppercase;
    opacity: 0;
    transition: opacity 0.5s ease-in 0.75s;
  }
  &.loading {
    height: 100%;
    bottom: 100%;
    transition: height 1s cubic-bezier(0.87, 0, 0.13, 1), bottom 0.5s cubic-bezier(0.87, 0, 0.13, 1) 2.25s;
    p {
      opacity: 1;
    }
  }
`;

export{
  StyledCurtainLoadWrap
}