import {
  StyledHeader
} from './StylesHeader';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';
import  SwitchLang  from '../SwitchLang/SwitchLang';
import MenuMobile from './MenuMobile';
import Hamburger from './Hamburger';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import {
  goToSlide,
  transitionLoader
} from '../../redux/actions/generalActions';

const Header = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isShowingMenu = useSelector((state) => state.general.isShowingMenu);
  const isOverlayOpen = useSelector((state) => state.general.isOverlayOpen);
  const isManifestoOpen = useSelector((state) => state.general.isManifestoOpen);
  const isTriggerLoad = useSelector((state) => state.general.triggerLoader);
  const history = useHistory();

  const sliderSlide = useSelector((state) => state.general.sliderSlide);

  const scrollToSection = (id) => {
    dispatch(goToSlide(id));
  }
  const handleRouteChange = useCallback((url) => {

    setTimeout(() => dispatch(transitionLoader(true)), 500);
    if(!isTriggerLoad) {
      setTimeout(() => history.push(url), 1500)
    };
    
  }, [isTriggerLoad])
  
  return (
    <>
    <StyledHeader 
      show={isShowingMenu && !isManifestoOpen} 
      isOverlayOpen={isOverlayOpen || isManifestoOpen} 
      isWhiteBg={sliderSlide === 3 || sliderSlide === 5}
    >
      <div className="logo">
        <img src="/img/logo-small.svg" />
      </div>
      <div className="nav-wrapper nav-desktop">
        <nav>
          <p className={ sliderSlide === 1 ? 'active' : ''} onClick={() => scrollToSection(1)}>
            {t('startHere')}
            <span></span>
          </p>
          <p className={ sliderSlide === 2 ? 'active' : ''} onClick={() => scrollToSection(2)}>
            Manifesto
            <span></span>
          </p>
          <p className={ sliderSlide === 3 ? 'active' : ''} onClick={() => scrollToSection(3)}>
            Beliefs
            <span></span>
          </p>
          <p className={ sliderSlide === 4 ? 'active' : ''} onClick={() => scrollToSection(4)}>
            Competency Model
            <span></span>
          </p>
          <p className={ sliderSlide === 5 ? 'active' : ''} onClick={() => scrollToSection(5)}>
            Stories
            <span></span>
          </p>
        </nav>
        <SwitchLang isWhiteBg={sliderSlide === 3 || sliderSlide === 4} />
      </div>
    </StyledHeader>
    <Hamburger isWhiteBg={sliderSlide === 3 || sliderSlide === 4 } />
    <MenuMobile />
    </>
  )
}

export default withTranslation()(Header);