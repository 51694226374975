
import {
  WrapCompetency,
  GridWrap,
  FixedTitle
} from './StylesCompetency';
import gsap from 'gsap';
import { useCallback, useEffect, useState } from 'react';
import InteractiveCursor from '../CursorSlider/InteractiveCursor';
import { transitionLoader } from '../../redux/actions/generalActions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';


const CompetencySlide = () => {
  const [mousePos, setMousePos] = useState({x: 0, y: 0});
  const history = useHistory()
  const dispatch = useDispatch();
  const isTriggerLoad = useSelector((state) => state.general.triggerLoader);
 
  const handleMove = useCallback((e) => {

    if (window.innerWidth > 767) {
      
      setMousePos({
        x: e.pageX,
        y: e.pageY
      });
      
    }
    
  });

  const handleChangeRoute = useCallback((url) => {
    
    dispatch(transitionLoader(true));

    if(!isTriggerLoad) setTimeout(() => history.push(url), 1000);
    
   }, [isTriggerLoad])

  return(
    <WrapCompetency onClick={() =>handleChangeRoute('/competency-model')}
        onMouseMove={(e) => handleMove(e)}>
      <InteractiveCursor 
        position={mousePos} 
        isShown={true} statusHover={"view"} />
      <FixedTitle>
        Competency<br />
        Model
      </FixedTitle>
    </WrapCompetency>
  )
}

export default CompetencySlide;