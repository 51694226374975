import styled from "styled-components";

const StyledSwitchedLang = styled.div`
  position: relative;
  margin: 0 40px 0 20px;
  box-sizing: border-box;
  color: ${ props => props.isWhiteBg ? `#001A72` : `#FFFFFF`};
  background-color: rgba(255, 255, 255, 0.15);
  font-size: 10px;
  line-height: 16px;
  cursor: pointer;
  text-transform: uppercase;
  transition: color .25s ease-in-out, background-color .25s ease-in-out;
  @media screen and (max-width: 1023px){
    position: absolute;
    bottom: 20px;
    font-size: 18px;
    line-height: 16px;
  }
  
  .current-lang {
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    z-index: 2;
    border: 1px solid ${ props => props.isWhiteBg ? `#001A72` : `#FFFFFF`};
    @media screen and (max-width: 1023px){
      padding: 17px 20px;
    }
  } 
  svg {
    position: relative;
    margin-left: 15px;
    display: block;
    transform: rotate(0);
    transition: transform 0.25s ease-in-out;
    path {
      stroke: ${ props => props.isWhiteBg ? `#001A72` : `#FFFFFF`};
      transition: stroke .25s ease-in-out;
    }
  }
  &:hover {
    color: ${ props => props.isWhiteBg ? `#FFFFFF` : `#001A72` };
    background-color: ${ props => props.isWhiteBg ? `#001A72` : `#FFFFFF`};
    svg {
      path {
        stroke: ${ props => props.isWhiteBg ? `#FFFFFF` : `#001A72` };
      }
    }
  }
  .list-lang {
    position: absolute;
    top: 37px;
    color: #001A72;
    background-color: #fff;
    width: 100%;
    left: 0;
    box-sizing: border-box;
    height: 0;
    overflow: hidden;
    transition: height 0.25s ease-in-out;
    @media screen and (max-width: 1023px){
      top: inherit;
      bottom: 52px;
      transform-origin: 0% 100%;
    }
    .lang-item {
      border: 1px solid #fff;
      border-bottom: 1px solid rgba(0, 26, 114, 0.15);
      transition: color .25s ease-in-out, background-color .25s ease-in-out, 
      border-color .25s ease-in-out;
      text-align: left;
      &:last-child {
        border-bottom: none;
      }
      p {
        padding: 9px 15px;
        text-transform: uppercase;
        @media screen and (max-width: 1023px){
          padding: 17px 20px;
        }
      }
      &:hover {
        color: #fff;
        background-color: #001A72;
        border-color: #001A72;
      }
    }
  }
  &.open {
    svg {
      transform: rotate(180deg);
    }
    .list-lang {
      border-top: 1px solid rgba(0, 26, 114, 0.15);
      height: 180px;
      box-shadow: 0 3px 10px 0px #a9a9a9;
      @media screen and (max-width: 1023px){
        height: 262px;
        box-shadow: none
      }
    }
  }
  
`;

export {
  StyledSwitchedLang
};