import styled from "styled-components";

const WrapCompetency = styled.div`
  position: relative;
  width: 100%;
  margin: 20px;
  height: calc(100% - 40px);
  background-color: transparent;
  overflow: hidden;
  border: 5px solid #fff;
  box-sizing: border-box;
  @media screen and (max-width: 1023px){
    margin: 0;
    height: 100%;
  }
`;

const FixedTitle = styled.h2`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 118px;
  color: #FFF;
  font-family: 'Everet Light';
  white-space: nowrap;
  text-align: center;
  pointer-events: none;
  @media screen and (max-width: 1023px) {
    font-size: 70px;
  }
  @media screen and (max-width: 767px) {
    font-size: 50px;
  }
`;

const GridWrap = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  .grid--slider {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    will-change: transform;
    .grid--row {
      position: absolute;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      img {
        position: absolute;
        max-width: 100%;
        min-height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
      }
    }
    
  }
`;


export {
  WrapCompetency,
  GridWrap,
  FixedTitle
}