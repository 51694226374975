import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import {
  StoriesWrapper,
  SlideWrap
} from './StylesStories';
import InteractiveCursor from '../CursorSlider/InteractiveCursor';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { transitionLoader } from '../../redux/actions/generalActions';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';


const Stories = () => {
  const [slider, setSlider] = useState(null);
  const [sliderIndex, setSliderIndex] = useState(0);
  const [isShown, setIsShown] = useState(false)
  const [mousePos, setMousePos] = useState({x: 0, y: 0});
  const [status, setStatus] = useState(null)
  const {t, i18n} = useTranslation();
  const sliderSlide = useSelector((state) => state.general.sliderSlide);
  const history = useHistory()
  const dispatch = useDispatch();
  const isTriggerLoad = useSelector((state) => state.general.triggerLoader);;
  

  const handleMove = useCallback((e) =>{
    setMousePos({
      x: e.pageX,
      y: e.pageY
    })

  })

  const handleHover = useCallback((e, state) => {
    // swiper-slide-active
    // swiper-slide-prev
    // swiper-slide-next
    const statusClass = e.currentTarget.classList.contains('swiper-slide-active') ?
                  "active" :
                  e.currentTarget.classList.contains('swiper-slide-prev') ? 
                  "prev" : "next";
    if(state === null){ 
      setStatus(null);
      setIsShown(false);   
    }else {
      setStatus(statusClass);
      if(!isShown) setIsShown(true)
    }        
  })

  useEffect(() => {

    window.addEventListener('mousemove', (e) => handleMove(e))

    return () => {
      window.removeEventListener('mousemove', handleMove)
    }
  },[])

  useMemo(() => {
    
    setIsShown(sliderSlide === 4)
    
  }, [sliderSlide])

  const handleChangeRoute = useCallback((url) => {
    
    dispatch(transitionLoader(true));

    if(!isTriggerLoad) setTimeout(() => history.push(url), 1000);
    
   }, [isTriggerLoad])

  return(
    <StoriesWrapper >
      <InteractiveCursor 
        position={mousePos} 
        isShown={isShown} statusHover={status} />
      <Swiper
        onSwiper={ swiper => {
          setSlider(swiper);
        }}
        spaceBetween={8}
        slidesPerView={1.15}
        onSlideChange={ (swiper) => {
            setSliderIndex(swiper.activeIndex);
          }
        }
        breakpoints={{
          "768": {
            "spaceBetween": 16,
            "slidesPerView": 1.5
          },
          "1024": {
            "spaceBetween": 44,
            "slidesPerView": 1.5
          }
        }}
        centeredSlides={true}
        speed={600}
      >
        <SwiperSlide 
          onMouseMove={(e) => handleHover(e)}
          onMouseLeave={(e) => handleHover(e, null)}
          onClick={
            () => {
              if(sliderIndex === 0) {
                handleChangeRoute('/story/leopardi')
              } 
              else slider.slideTo(0);
            }
          }
          tag="article">
          <SlideWrap style={{
            left: sliderIndex === 0 ? 0 : "-100px"
          }}
          >
            <div className="slide--img" >
              <img src={t('storiesPage.leopardi.slide-img')} />
            </div>
            <div className="slide--info-wrap">
              <div className="slide-excerpt">
                <h4>
                  {
                    t('storiesPage.leopardi.excerpt-title')
                  }
                </h4>
              </div>
              <div className="slide-author">
                <p className="author">
                  {
                    t('storiesPage.leopardi.author')
                  }
                </p>
                <p className="author-role">
                  {
                    t('storiesPage.leopardi.author-role')
                  }
                </p>
              </div>
            </div>
          </SlideWrap>
        </SwiperSlide>
        <SwiperSlide 
          onMouseMove={(e) => handleHover(e)}
          onMouseLeave={(e) => handleHover(e, null)}
          onClick={
            () => {
              if(sliderIndex === 1) {
                handleChangeRoute('/story/manta')
              } 
              else slider.slideTo(1);
            }
          }
          tag="article">
          <SlideWrap style={{
            right: (sliderIndex === 1 || sliderIndex > 1) ? "inherit" : "-100px",
            left: (sliderIndex === 1 || sliderIndex < 1) ? "inherit" : "-100px"
          }}>
            <div className="slide--img" >
              <img src={t('storiesPage.manta.slide-img')} />
            </div>
            <div className="slide--info-wrap">
              <div className="slide-excerpt">
                <h4>
                  {
                    t('storiesPage.manta.excerpt-title')
                  }
                </h4>
              </div>
              <div className="slide-author">
                <p className="author">
                  {
                    t('storiesPage.manta.author')
                  }
                </p>
                <p className="author-role">
                  {
                    t('storiesPage.manta.author-role')
                  }
                </p>
              </div>
            </div>
          </SlideWrap>
        </SwiperSlide>
        <SwiperSlide 
          onMouseMove={(e) => handleHover(e)}
          onMouseLeave={(e) => handleHover(e, null)}
          onClick={
            () => {
              if(sliderIndex === 2) {
                handleChangeRoute('/story/alemanno')
              } 
              else slider.slideTo(2);
            }
          }
          tag="article">
          <SlideWrap style={{
            right: (sliderIndex === 2 || sliderIndex > 2) ? "inherit" : "-100px",
            left: (sliderIndex === 2 || sliderIndex < 2) ? "inherit" : "-100px"
          }}>
            <div className="slide--img" >
              <img src={t('storiesPage.alemanno.slide-img')} />
            </div>
            <div className="slide--info-wrap">
              <div className="slide-excerpt">
                <h4>
                  {
                    t('storiesPage.alemanno.excerpt-title')
                  }
                </h4>
              </div>
              <div className="slide-author">
                <p className="author">
                  {
                    t('storiesPage.alemanno.author')
                  }
                </p>
                <p className="author-role">
                  {
                    t('storiesPage.alemanno.author-role')
                  }
                </p>
              </div>
            </div>
          </SlideWrap>
        </SwiperSlide>
        <SwiperSlide 
          onMouseMove={(e) => handleHover(e)}
          onMouseLeave={(e) => handleHover(e, null)}
          onClick={
            () => {
              if(sliderIndex === 4) {
                handleChangeRoute('/story/noordink')
              } 
              else slider.slideTo(4);
            }
          }
          tag="article">
          <SlideWrap style={{
            right: (sliderIndex === 4 || sliderIndex > 4) ? "inherit" : "-100px",
            left: (sliderIndex === 4 || sliderIndex < 4) ? "inherit" : "-100px"
          }}>
            <div className="slide--img" >
              <img src={t('storiesPage.noordink.slide-img')} />
            </div>
            <div className="slide--info-wrap">
              <div className="slide-excerpt">
                <h4>
                  {
                    t('storiesPage.noordink.excerpt-title')
                  }
                </h4>
              </div>
              <div className="slide-author">
                <p className="author">
                  {
                    t('storiesPage.noordink.author')
                  }
                </p>
                <p className="author-role">
                  {
                    t('storiesPage.noordink.author-role')
                  }
                </p>
              </div>
            </div>
          </SlideWrap>
        </SwiperSlide>
        <SwiperSlide 
          onMouseMove={(e) => handleHover(e)}
          onMouseLeave={(e) => handleHover(e, null)}
          onClick={
            () => {
              if(sliderIndex === 5) {
                handleChangeRoute('/story/auricchio')
              } 
              else slider.slideTo(5);
            }
          }
          tag="article">
          <SlideWrap style={{
            right: (sliderIndex === 5 || sliderIndex > 5) ? "inherit" : "-100px",
            left: (sliderIndex === 5 || sliderIndex < 5) ? "inherit" : "-100px"
          }}>
            <div className="slide--img" >
              <img src={t('storiesPage.auricchio.slide-img')} />
            </div>
            <div className="slide--info-wrap">
              <div className="slide-excerpt">
                <h4>
                  {
                    t('storiesPage.auricchio.excerpt-title')
                  }
                </h4>
              </div>
              <div className="slide-author">
                <p className="author">
                  {
                    t('storiesPage.auricchio.author')
                  }
                </p>
                <p className="author-role">
                  {
                    t('storiesPage.auricchio.author-role')
                  }
                </p>
              </div>
            </div>
          </SlideWrap>
        </SwiperSlide>
        <SwiperSlide 
          onMouseMove={(e) => handleHover(e)}
          onMouseLeave={(e) => handleHover(e, null)}
          onClick={
            () => {
              if(sliderIndex === 6) {
                handleChangeRoute('/story/tommasato')
              } 
              else slider.slideTo(6);
            }
          }
          tag="article">
          <SlideWrap style={{
            right: (sliderIndex === 6 || sliderIndex > 6) ? "inherit" : "-100px",
            left: (sliderIndex === 6 || sliderIndex < 6) ? "inherit" : "-100px"
          }}>
            <div className="slide--img" >
              <img src={t('storiesPage.tommasato.slide-img')} />
            </div>
            <div className="slide--info-wrap">
              <div className="slide-excerpt">
                <h4>
                  {
                    t('storiesPage.tommasato.excerpt-title')
                  }
                </h4>
              </div>
              <div className="slide-author">
                <p className="author">
                  {
                    t('storiesPage.tommasato.author')
                  }
                </p>
                <p className="author-role">
                  {
                    t('storiesPage.tommasato.author-role')
                  }
                </p>
              </div>
            </div>
          </SlideWrap>
        </SwiperSlide>
        <SwiperSlide 
          onMouseMove={(e) => handleHover(e)}
          onMouseLeave={(e) => handleHover(e, null)}
          onClick={
            () => {
              if(sliderIndex === 7) {
                handleChangeRoute('/story/pirovano')
              } 
              else slider.slideTo(7);
            }
          }
          tag="article">
          <SlideWrap style={{
            right: (sliderIndex === 7 || sliderIndex > 7) ? "inherit" : "-100px",
            left: (sliderIndex === 7 || sliderIndex < 7) ? "inherit" : "-100px"
          }}>
            <div className="slide--img" >
              <img src={t('storiesPage.pirovano.slide-img')} />
            </div>
            <div className="slide--info-wrap">
              <div className="slide-excerpt">
                <h4>
                  {
                    t('storiesPage.pirovano.excerpt-title')
                  }
                </h4>
              </div>
              <div className="slide-author">
                <p className="author">
                  {
                    t('storiesPage.pirovano.author')
                  }
                </p>
                <p className="author-role">
                  {
                    t('storiesPage.pirovano.author-role')
                  }
                </p>
              </div>
            </div>
          </SlideWrap>
        </SwiperSlide>
      </Swiper>
    </StoriesWrapper>
  )
}

export default withTranslation()(Stories);