import styled from "styled-components";

const CursorWrap = styled.div`
  position: ${props => props.isFixed ? 'fixed' : 'absolute' }; ;
  z-index: 5;
  width: 157px;
  height: 157px;
  background-color: rgba(0, 26, 114, 0.5);
  border: 1px solid #fff;
  border-radius: 50%;
  opacity: ${props => props.isShown ? 1 : 0};
  transform: translate(-50%, -50%);
  transition: opacity 0.15s ease-in-out, left 0s ease-out, top 0s ease-out;
  pointer-events: none;
  display: flex;
  align-items: center;
  text-align: center;
  p {
    margin: 0 auto;
    font-size: 14px;
    color: #fff;
  }
  img {
    margin: 0 auto;
  }
  @media screen and (max-width: 1023px){
    display: none;
  }
`;

export {
  CursorWrap
}