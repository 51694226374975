import { useCallback, useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Player from '@vimeo/player';
import {
  goToSlide,
  transitionLoader,
  updateOnBack
} from '../../redux/actions/generalActions';
import { useTranslation, withTranslation } from 'react-i18next';
import {StyledHeaderStoryPage} from './StylesHeaderStoryPage';

const HeaderStoryPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const slug = useParams();
  const { t } = useTranslation();
  const vimeoRef = useRef(null);
  const [firstLoad, setFirstLoad] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [player, setPlayer] = useState(null);
  const [bgImage, setBgImage] = useState(null)

  const isTriggerLoad = useSelector((state) => state.general.triggerLoader);
  
  const goBack = () => {
    
    dispatch(transitionLoader(true));
    dispatch(updateOnBack(true));
    
    if(!isTriggerLoad) setTimeout(() => {
      history.push('/');
      
      dispatch(goToSlide(3));
    }, 1000);
  }

  useEffect(() => {
    setTimeout(() => setFirstLoad(false), 0);
    setTimeout(() => setFirstLoad(true), 2250);
    const videoId = t(`storiesPage.${slug.id}.article-video-id`);

    if(player !== null) {
      player.destroy();
      setPlayer(null);
      const vimeoOptions = {
        controls: false,
        url: videoId,
        width: window.innerWidth,
        autoplay: false,
        muted: false
      };
      setPlayer(new Player(vimeoRef.current, vimeoOptions));
      setBgImage(t(`storiesPage.${slug.id}.slide-img`));
    
    }
  }, [slug]);

 useEffect(() => {
  const videoId = t(`storiesPage.${slug.id}.article-video-id`);
  
  if(player === null && (videoId.includes('https') && videoId !== null) ){

    const vimeoOptions = {
      controls: false,
      url: videoId,
      width: window.innerWidth,
      autoplay: false,
      muted: false
    };
    setPlayer(new Player(vimeoRef.current, vimeoOptions));

  }
  if(t !== undefined)
    setBgImage(t(`storiesPage.${slug.id}.slide-img`));
 },[t])

 useEffect(() => {
  if(player !== null) {
    player.on('pause', function() {
      setIsPlaying(false)
    });
  }
 }, [player])

  const togglePlay = useCallback(() => {
    if(isPlaying) player.pause()
    else player.play()
    setIsPlaying(!isPlaying);
    
  }, [isPlaying,player])

  return(
    <StyledHeaderStoryPage isLoaded={firstLoad}>
      <div className="header-bar">
        <div className="back-btn" onClick={goBack}>
          <img src="/img/arrow-white-back.svg" />
          <p>STORIES</p>
        </div>
      </div>
      <div className="video-wrap" >
        <div 
            onClick={togglePlay}
            className={ isPlaying ? "poster play--video" : "poster"} 
            style={{backgroundImage: `url(${ bgImage })` }} >
          <img src="/img/play-cover.svg" />
        </div>
        <div ref={vimeoRef} className="video-frame"></div>
      </div>
    </StyledHeaderStoryPage>
  )
}

export default withTranslation()(HeaderStoryPage);