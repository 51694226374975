const TOGGLE_MENU = "TOGGLE_MENU";
const TOGGLE_OVERLAY = "TOGGLE_OVERLAY";
const TRIGGER_LOADER = "TRIGGER_LOADER";
const TOGGLE_MANIFESTO = "TOGGLE_MANIFESTO";
const TOGGLE_MENU_MOBILE = "TOGGLE_MENU_MOBILE";
const GO_TO_SLIDE = "GO_TO_SLIDE";
const UPDATE_ID_SLIDE = "UPDATE_ID_SLIDE";

export {
  TOGGLE_MENU,
  TOGGLE_OVERLAY,
  TRIGGER_LOADER,
  TOGGLE_MANIFESTO,
  TOGGLE_MENU_MOBILE,
  GO_TO_SLIDE,
  UPDATE_ID_SLIDE
}