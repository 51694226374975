import {
  TOGGLE_MENU,
  TOGGLE_OVERLAY,
  TRIGGER_LOADER,
  TOGGLE_MANIFESTO,
  TOGGLE_MENU_MOBILE,
  GO_TO_SLIDE,
  UPDATE_ID_SLIDE
} from "../types";

const INITIAL_STATE = {
  isShowingMenu: false,
  isOverlayOpen: false,
  triggerLoader: false,
  isManifestoOpen: false,
  isMenuMobileOpen: false,
  sliderSlide: 0,
  backBtn: false
}

const general = (state = INITIAL_STATE, action) => {
  
  switch (action.type) {
    case TOGGLE_MENU:
      return {
        ...state,
        isShowingMenu: action.value
      };
    case TOGGLE_OVERLAY:
      return {
        ...state,
        isOverlayOpen: action.value
      };
    case TRIGGER_LOADER:
      return {
        ...state,
        triggerLoader: action.value
      };
    case TOGGLE_MANIFESTO:
      return {
        ...state,
        isManifestoOpen: action.value
      };
    case TOGGLE_MENU_MOBILE:
      return {
        ...state,
        isMenuMobileOpen: action.value
      };
    case GO_TO_SLIDE:
      return {
        ...state,
        sliderSlide: action.value
      }
    case UPDATE_ID_SLIDE:
      return {
        ...state,
        backBtn: action.value
      }
    
    default: return state;
  }
}


export default general;
