import {
  StyledCurtainLoadWrap
} from './StylesCurtainLoad';

import {useSelector, useDispatch} from 'react-redux'
import { useRef, useEffect } from 'react';
import { transitionLoader } from '../../redux/actions/generalActions';

const CurtainLoad = (props) => {
  const dispatch = useDispatch();
  const curtain = useRef(null);

  const isTriggerLoad = useSelector((state) => state.general.triggerLoader);
 
  useEffect(() =>{

    if(isTriggerLoad){
      curtain.current.classList.add('loading');
      setTimeout( () => dispatch(transitionLoader(false)), 2550);
    } else {
      curtain.current.classList.remove('loading');
    }
    

  },[isTriggerLoad]);

  return(
    <StyledCurtainLoadWrap ref={curtain}>
      <p>loading...</p>
    </StyledCurtainLoadWrap>
  )
}

export default CurtainLoad;