import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';
import {
  toggleMenuMobile,
  goToSlide,
  transitionLoader
} from '../../redux/actions/generalActions';
import { useHistory } from 'react-router-dom';
import {useCallback} from 'react';
import {
  StyledMenuMobileWrap
} from './StylesHeader';
import  SwitchLang  from '../SwitchLang/SwitchLang';

const MenuMobile = () => {
  const history = useHistory()
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  const isMobileMenuOpen = useSelector((state) => state.general.isMenuMobileOpen);
  const sliderSlide = useSelector((state) => state.general.sliderSlide);
  const isTriggerLoad = useSelector((state) => state.general.triggerLoader);;

  const scrollToSection = (id) => {
    dispatch(toggleMenuMobile(!isMobileMenuOpen));
    setTimeout(() => {
      dispatch(goToSlide(id));
    }, 500)
  }

  const handleRouteChange = useCallback((url) => {
    dispatch(toggleMenuMobile(!isMobileMenuOpen));
    setTimeout(() =>dispatch(transitionLoader(true)), 500);
    if(!isTriggerLoad) {
      setTimeout(() => history.push(url), 1500)
    };
    
   }, [isTriggerLoad,isMobileMenuOpen])
  
  return(
    <StyledMenuMobileWrap isMobileMenuOpen={isMobileMenuOpen}>
      <nav>
        <p className={ sliderSlide === 1 ? 'active' : ''} onClick={() => scrollToSection(1)}>
          <span>
            {t('startHere')}
          </span>
        </p>
        <p className={ sliderSlide === 2 ? 'active' : ''} onClick={() => scrollToSection(2)}>
          <span>
            Manifesto
          </span>
        </p>
        <p className={ sliderSlide === 3 ? 'active' : ''} onClick={() => scrollToSection(3)}>
          <span>
            Beliefs
          </span>
        </p>
        <p className={ sliderSlide === 4 ? 'active' : ''} onClick={() => scrollToSection(4)}>
          <span>
            Competency Model
          </span>
        </p>
        <p className={ sliderSlide === 5 ? 'active' : ''} onClick={() => scrollToSection(5)}>
          <span>
            Stories
          </span>
        </p>
        <p onClick={() => handleRouteChange('/challenge')}>
          <span>
            Challenge
          </span>
        </p>
      </nav>
      <SwitchLang />
      <div className="cookie-page">
        <span><a href="/privacy-cookie-policy" target="_blank">Cookie & Privacy Policy</a></span>
      </div>
    </StyledMenuMobileWrap>
  )
}

export default withTranslation()(MenuMobile);