
import React, { useEffect, useState, useCallback, useMemo} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { toggleMenu, toggleOverlay, goToSlide, updateOnBack } from "../../redux/actions/generalActions";
import { useTranslation, withTranslation } from 'react-i18next';

import { 
  StyledSliderWrap,
  StyledSliderMain,
  StyledScrollIndicator
} from './StylesSliderWrap';
import {
  VimeoPlayer,
  Beliefs,
  Stories,
  CompetencySlide
} from '../../components/';

import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, {
  Mousewheel
} from 'swiper';

SwiperCore.use([Mousewheel]);

const SliderWrap = () => {
  const dispatch = useDispatch();
  const {t, i18n} = useTranslation();
  const isShowingMenu = useSelector((state) => state.general.isShowingMenu);
  const isOverlayOpen = useSelector((state) => state.general.isOverlayOpen);
  const isManifestoOpen = useSelector((state) => state.general.isManifestoOpen);
  const sliderSlide = useSelector((state) => state.general.sliderSlide);
  const backBtn = useSelector((state) => state.general.backBtn);

  const [slider, setSlider] = useState(null);
  const [activeIndex,setActiveIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(null);
  
  const handleChange = useCallback((index) => {
    setActiveIndex(index);
  },[activeIndex])

  const resize = () => {
    let isMobileWidth = window.innerWidth <= 1023;

    if(isMobile !== isMobileWidth ){
      setIsMobile(isMobileWidth);
    }
  }
  
  useEffect(() => {
    
    if(activeIndex > 0 && !isShowingMenu){ 
      dispatch(toggleMenu(true));
    }

    if(activeIndex === 0 && isShowingMenu) {
      dispatch(toggleMenu(false));
      if(isOverlayOpen) dispatch(toggleOverlay(false))
    }
    if(backBtn) {
      
      dispatch(goToSlide(sliderSlide));
      dispatch(updateOnBack(false));
      setActiveIndex(sliderSlide)
    }else {
      dispatch(goToSlide(activeIndex));
    }
    
  }, [activeIndex, backBtn])

  useMemo(() => {
    if(slider !== null && !backBtn){
      slider.slideTo(sliderSlide);
    }
  },[sliderSlide, backBtn])

  useEffect(() => {
    window.addEventListener('resize', resize);
    resize();
    return () => {
      window.removeEventListener('resize', resize);
    }
  }, [])
  return (
    <>
      {isMobile !== null &&
      <StyledSliderWrap hide={isManifestoOpen}>
        <StyledSliderMain>
          <Swiper
            onSwiper={ swiper => {
              setSlider(swiper)
              swiper.slideTo(sliderSlide);
              setActiveIndex(sliderSlide);
            } }
            onSlideChange={(swiper) => {
              handleChange(swiper.activeIndex);
            }}
            
            slidesPerView={1}
            direction={'vertical'}
            speed={600}
            mousewheel={true}
            
          >
            <SwiperSlide tag="section" className="home--slide">
              <div className="logo--wrap">
                <img src="/img/logo-head.svg" />
              </div>
              <h1>IMAGINE BIG.<br/>DO BETTER.</h1>
              <StyledScrollIndicator>
                <span>
                  {t('scrollDown')}
                </span>
                <div className="line--indicator"></div>
              </StyledScrollIndicator>
              <div className="cookie-page">
                <span>
                  <a href="/privacy-cookie-policy" target="_blank">Cookie & Privacy Policy</a>
                </span>
              </div>
            </SwiperSlide>
            <SwiperSlide tag="section" >
              <VimeoPlayer 
                videoId={isMobile ? t('videoIdStartMobile') : t('videoIdStart')} 
                isActive={activeIndex === 1} 
              />
            </SwiperSlide>
            <SwiperSlide tag="section" >
              <VimeoPlayer 
                videoId={isMobile ? t('videoIdManifestoMobile') : t('videoIdManifesto')} 
                isActive={activeIndex === 2} 
                hasCta={true}
              />
            </SwiperSlide>
            <SwiperSlide tag="section" >
              <Beliefs />
              </SwiperSlide>
            <SwiperSlide tag="section">
              <CompetencySlide />
            </SwiperSlide>
            <SwiperSlide tag="section">
              <Stories />
            </SwiperSlide>
          </Swiper>
        </StyledSliderMain>
      </StyledSliderWrap>}
    </>
  )
}

export default withTranslation()(SliderWrap);