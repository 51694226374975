import React, {useEffect, useRef} from "react";
import {StyledPrivacyContent} from './StylesCookiePage.js' 
import Scrollbar from 'smooth-scrollbar';
import { useHistory } from 'react-router-dom';
import { transitionLoader } from '../../redux/actions/generalActions';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';

const CookiePrivacy = () => {
  const content = useRef(null);
  const history = useHistory()
  const dispatch = useDispatch();
  const isTriggerLoad = useSelector((state) => state.general.triggerLoader);;
  
  const handleChange = useCallback(() => {
    
    dispatch(transitionLoader(true));

    if(!isTriggerLoad) setTimeout(() => history.push('/'), 1000);
    
   }, [isTriggerLoad]);

  useEffect(()=>{
    if(content.current !== null)
    Scrollbar.init(content.current, {damping: 0.075})
  },[content]);

    return ( 
      <StyledPrivacyContent ref={content}>
        <div id="back-home" onClick={handleChange}>
          <img src="/img/logo-small.svg" /> 
        </div>
        <div id="privacy-cookie-content"></div>
      </StyledPrivacyContent>
  )
}

export default CookiePrivacy;