import React, {useRef} from 'react';
import * as THREE from "three";
import { useFrame, useThree } from '@react-three/fiber'
import  '../../shaders/GradientMaterial';


const GradientBg = () => {
  const renderRef = useRef()
  const { width, height } = useThree((state) => state.viewport)

  let j = 3.01;
  let direction = 1;
  
  useFrame(() => {
    
    renderRef.current.uniforms.u_randomisePosition.value = new THREE.Vector2(j, j);
    if( (j < 3) && (direction === -1)) {
      j += 0.01;
      direction = 1;
    }else if( (j >= 35) && (direction === 1)){ 
      j -= 0.01;
      direction = -1;
    } else if( (j < 35 && j > 3) && (direction === -1)){ 
      j -= 0.01;
    }else if( (j < 35 && j > 3) && (direction === 1)){ 
      j += 0.01;
    }
          
  })


  return(
    <>
      <mesh scale={[width, height, 0.25]}>
        <planeGeometry  args={[1, 1, 100, 100]} />
        <gradientMaterial 
          ref={renderRef} 
          u_color1={new THREE.Color("#009EE2")} // azzurro
          u_color2={new THREE.Color("#001A72")} // blu
          u_bgMain={new THREE.Color("#E5007E")} // magenta
          u_bg={new THREE.Color("#FFFFFF")} // bianco
          
        />
      </mesh>
    </>
  )
}

export default GradientBg;