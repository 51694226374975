import styled from "styled-components";

const StyledVimeoPlayerWrap = styled.div`
  position: relative;
  width: 100%;
  margin: 20px;
  height: calc(100% - 40px);
  z-index: 1;
  transition: margin 0.25s ease-out, height 0.25s ease-out;
  &.full-screen {
    margin: 0;
    height: 100%;
  }
  @media screen and (max-width: 1023px){
    margin: 0;
    height: 100%;
  }
  .load--video {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    overflow: hidden;
    z-index: 5;
    background-color: rgba(0, 26, 114, 1);
  }
`;
const StyledVimeoPlayer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
  overflow: hidden;
  background-color: transparent;
  transition: background-color 0.25s linear;
  pointer-events: none;
  z-index: -1000;
  >div, iframe{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1000;
    pointer-events: none;
  }
  iframe {
    width: 100%;
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100%;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    pointer-events: none;
    z-index: -1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: min-height 0.25s linear, min-width 0.25s linear,
                height 0.25s linear;
    @media screen and (max-width: 1023px){
      width: 56.25vh;
      height: 100%; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
      min-height: 177.77vh;
      min-width: 100%; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    }
  }
  &.horizontal {
    @media screen and (max-width: 1023px){
      iframe {
        min-height: auto;
      }
    }
  }
  &.vertical{  
    @media screen and (max-width: 1023px){
      iframe {
        width: 56.25vh;
        height: 100%; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
        min-height: 177.77vh;
        min-width: 100%; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
      }
    }
  }
  iframe body {
    pointer-events: none;
  }
  &.full-screen {
    background-color: #000;
    iframe {
      height: 100%;
      min-height: 100%;
      min-width: 100%;
    }
  }
`;

const StyledVideoControls = styled.div`
  position: absolute;
  padding-bottom: 20px;
  bottom: 0;
  width: 100%;
  display: flex;
  height: calc(100% - 20px);
  align-items: flex-end;
  justify-content: space-between;
  z-index: 3;
  @media screen and (max-width: 1023px){
    bottom: 0px;
  }
  .toggle-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
  .play-toggle {
    flex: 0 0 44px;
    position: relative;
    width: 44px;
    height: 44px;
    overflow: hidden;
    margin: 0 10px;
    cursor: pointer;
    @media screen and (max-width: 1023px){
      margin: 0 8px ${props => props.withCta ? '70px' : '20px'};
    }
    .pause, .play {
      position: relative;
      width: 44px;
      height: 44px;
      margin-top: 0px;
      transition: margin .15s ease-in-out;
      display: flex;
      align-items: center;
      img {
        display: block;
        margin: 0 auto
      }
    }
    &.playing {
      .play {
        margin-top: -44px;
      }
    }
  }
  .load-time {
    position: relative;
    flex: 1 1 auto;
    height: 2px;
    background-color: rgba(255,255,255,0.5);
    margin-bottom: 23px;
    overflow: hidden;
    .loaded-time{
      position: absolute;
      left: 0;
      width: 0;
      height: 2px;
      background-color: #fff;
    }
    @media screen and (max-width: 1023px){
      position: absolute;
      width: calc(100% - 40px);
      left: 20px;
      bottom: ${props => props.withCta ? '50px' : 0};
    }
  }
  .controls-wrap {
    position: ${props => props.withCta ? 'static' : 'relative'};
    flex: 0 0 100px;
    margin: 0 20px;
    display: flex;
    align-items: center;
    z-index: 4;
    @media screen and (max-width: 1023px){
      flex: 0 0 50px !important;
      margin: 0 10px;
    }
    .full-screen {
      position: relative;
      display: flex;
      align-items: center;
      width: 50px;
      height: 50px;
      margin-right: 15px;
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.25s ease-in-out;
      &:hover {
        opacity: 0.7;
        @media screen and (max-width: 1023px){ 
          opacity: 1;
        }
      }
      img {
        display: block;
        margin: 0 auto;
      }
      @media screen and (max-width: 1023px){
        display: none !important;
      }
    }
    .audio-toggle {
      position: relative;
      width: 50px;
      height: 50px;
      overflow: hidden;
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.25s ease-in-out;
      &:hover {
        opacity: 0.7;
        @media screen and (max-width: 1023px){ 
          opacity: 1;
        }
      }
      @media screen and (max-width: 1023px){
        margin-bottom: ${props => props.withCta ? '70px' : '20px'};
      }
      .unmute, .mute {
        position: relative;
        width: 50px;
        height: 50px;
        margin-top: 0px;
        transition: margin .15s ease-in-out;
        display: flex;
        align-items: center;
      }
      img {
        display: block;
        margin: 0 auto;
        &.mobile {
          display: none;
        }
        @media screen and (max-width: 1023px){
          display: none !important;
          &.mobile {
            display: block !important;
          }
        }
      }
      &.is-unmute {
        .unmute {
          margin-top: -50px;
        }
      }
    }
    .cta--manifesto {
      padding: 19px 20px 18px;
      background-color: #FFFFFF;
      color: #001A72;
      font-size: 12px;
      line-height: 13px;
      white-space: nowrap;
      text-transform: uppercase;
      margin-left: 20px;
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.25s ease-in-out;
      &:hover {
        opacity: 0.7;
        @media screen and (max-width: 1023px){ 
          opacity: 1;
        }
      }
      @media screen and (max-width: 1023px){ 
        position: absolute;
        bottom: 0;
        right: 20px;
        left: 20px;
        margin: 0 0 10px;
        overflow: hidden;
        text-align: center;
      }
    }
  }
`;

const StyledVideoClose = styled.div`
  position: absolute;
  top: 26px;
  right: 20px;
  z-index: 2;
  display: none;
  cursor: pointer;
  img {
    display: block;
  }
`;
export {
  StyledVimeoPlayerWrap,
  StyledVimeoPlayer,
  StyledVideoControls,
  StyledVideoClose
}